.crm-form-builder .react-form-builder>div{
  display: flex;
}
.crm-form-builder .react-form-builder .float-left {
  width: 75%;
}
.crm-form-builder .react-form-builder .float-right {
  width: 25%;
  margin-top: 0;
}
