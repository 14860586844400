// @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css");

// fonts
@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "anonymous-pro";
  src: url("https://use.typekit.net/af/d359b5/000000000000000077359719/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/d359b5/000000000000000077359719/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/d359b5/000000000000000077359719/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}
// fonts ends

// variables starts

// colours
$purple1: #201b50;
$purple2: #5a4cdc;
$lightpurple: #7b6fe2;
$blue: #00254d;
$lightgray: #ced4da;
$lightgray1: #6b778c;
$lightgray2: #6f6f6f;
$lightgray3: #dee2e6;
$white: #fff;

$primary: #007d87;
$black: #000000;
$dark: #2e2e2e;
$gray: #7a7a7a;
$grayTint0: #fafafa;
$grayTint1: #f8f8f8;
$grayTint3: #eff0f4;

// Dynamic Theme colours
$primaryColor: #201b50;
$primaryColor2: #494479;
$secondaryColor2: #5a4cdc;
$greyTextColor: #6b778c;
$formTextColor: #201b50;
$tabActive: #c5bff9;
$menuText: #e6f2ff;
$boxBg: #f3f3f7;
$yellow: #ffc107;
$yellowTint1: #fff6ec;

.extralightGreyBg {
  background-color: $grayTint0;
}
.lightGreyBg {
  background-color: $grayTint1;
}

// position
$relative: relative;
$absolute: absolute;
$fixed: fixed;

// fonts
$fs-10: 10px;
$fs-11: 11px;
$fs-12: 12px;
$fs-14: 14px;
$fs-15: 15px;
$fs-16: 16px;
$normal: 400;
$regular: 500;
$semi-bold: 600;
$bold: 700;
$capitalize: capitalize;
$uppercase: uppercase;

// alignment
$center: center;

// variables ends

//Thin ScrollBar
:root {
  --body-bg-color: #e3e3e3;
  --scroll-color: #c3bebe;
  --scroll--hover-color: #c3bebe;
  --scroll-track-color: #e3dede;
}
* {
  scrollbar-color: #c3bebe #e3dede;
  scrollbar-width: thin;
}
* {
  &::-webkit-scrollbar-track {
    background: linear-gradient(
      to right,
      var(--scroll-track-color) 0%,
      var(--scroll-track-color) 100%,
      var(--scroll-color) 60%,
      var(--body-bg-color) 61%,
      var(--body-bg-color) 100%
    );
  }
}
* {
  &::-webkit-scrollbar {
    width: 4px;
  }
}
* {
  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 0;
  }
}
//ScrollBar
::-webkit-scrollbar {
  background: transparent;
  width: 5px;
  height: 5px;
  margin-left: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

body {
  // background-color: #ebebeb;
  // background-color: #f3f3f3;
  // font-family: "Lato", sans-serif;
  font-family: "proxima-nova", sans-serif;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $primary;
  }
}

// loader css starts
.lds-roller {
  display: inline-block;
  position: $relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: $absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $primary;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn.btn-clear {
  background-color: $lightgray;
  color: $dark;
  &:hover {
    background-color: $lightgray;
    color: $dark;
  }
  &:active {
    background-color: $lightgray;
    color: $dark;
  }
}

.btn.btn-save {
  background-color: $primary;
  color: $white;
  &:hover {
    background-color: $primary;
    color: $white;
  }
  &:active {
    background-color: $primary;
    color: $white;
  }
}
.innerLoader {
  width: 100%;
  height: 100%;
  position: $fixed;
  display: flex;
  align-items: $center;
  justify-content: $center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  top: 0;
}
// loader css ends

.payment-loader {
  width: 48px;
  height: 48px;
  border: 5px solid $purple1;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// common css starts
a {
  cursor: pointer;
}
.addItem-text {
  text-decoration: none;
  text-align: right;
  font-size: 13px;
  color: $purple2;
  &:hover {
    color: $purple2;
  }
}
.w65-px {
  width: 65px;
}
.loaderouter {
  position: $absolute;
  top: 50%;
  display: flex;
  // background: #f7f7f7;
  background-color: $white;
  padding: 6px 20px;
  border-radius: 50px;
  left: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: $center;
  @media (max-width: 575px) {
    transform: translate(-50%, -50%);
  }
  .sectionLoading {
    margin-left: 10px;
    font-size: 13px;
    color: $blue;
  }
}
.h-100vh {
  height: 100vh;
}
.h-80vh {
  height: 80vh;
}
.heading {
  text-align: left;
  h2 {
    color: $dark;
    font-size: 26px;
    font-weight: 700;
  }
}
.pl-240 {
  padding-left: 240px;
  @media (max-width: 991px) {
    padding-left: 15px;
  }
}
.pr-80 {
  padding-right: 80px;
}
.commonPadding {
  padding: 35px 0;
}
.horizontalPadding {
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
  }
}
.pill {
  padding: 4px 6px;
  border-radius: 5px;
  font-weight: $semi-bold;
  font-size: $fs-14;
  &.pill-grey {
    color: $lightgray1;
    background-color: #f3f3f7;
    width: max-content;
  }
  &.pill-green {
    background-color: #d8f6df;
    color: #28a745;
    width: max-content;
  }
  &.pill-orange {
    color: #fdbf33;
    background-color: #ffefcb;
    width: max-content;
  }
  &.pill-blue {
    color: #007bff;
    background-color: #cce5ff;
    width: max-content;
  }
  &.pill-red {
    color: #f00;
    background-color: rgba(255, 0, 0, 0.3);
    width: max-content;
  }
}
.fs-12 {
  font-size: $fs-12;
}
.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  &.circle-warning {
    background-color: #ffc107;
  }
  &.circle-spam {
    background-color: $lightgray1;
  }
  &.circle-success {
    background-color: #28a745;
  }
}
.dropdown {
  .dropdown-toggle {
    background-color: transparent !important;
    border: none;
    &::after {
      display: none;
    }
    &:hover {
      background-color: transparent;
    }
  }
  .dropdown-menu {
    border: none;
    box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15);
    li {
      a {
        color: #6f6f6f !important;
        font-size: $fs-14;
        &:focus {
          background-color: #fafafa;
        }
        &:hover {
          background-color: #fafafa;
        }
      }
    }
  }
}
.section-filter {
  text-align: right;
  .btn-import {
    background-color: $white;
    box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15%);
    font-size: $fs-14;
    border-radius: 10px;
    &:hover {
      background-color: $white;
    }
    &:hover {
      border-color: $white;
    }
  }
  .add-btn {
    background-color: $purple2;
    color: $white;
    text-decoration: none;
    display: inline-block;
    padding: 6px 10px;
    border-radius: 10px;
    font-size: $fs-14;
    margin-right: 10px;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: $lightpurple;
    }
    &.ticketAdd-btn {
      padding: 7px 10px;
      border-radius: 10px;
    }
  }
  .dropdown-toggle {
    font-size: $fs-14;
    &::after {
      display: none;
    }
    &.btn-filter {
      background-color: $purple2;
      border-color: $purple2;
      margin-right: 10px;
      padding: 7px 12px;
      border-radius: 10px;
      &:hover {
        background-color: $lightpurple;
        border-color: $lightpurple;
      }
    }
    &.btn-info {
      background: $white;
      border: 1px solid $white;
      box-shadow: 0 1px 1px 0 #dfe1e6;
      color: #42526e;
      border-radius: 10px;
    }
  }
  .dropdown-menu {
    border: none;
    box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    li {
      .dropdown-item {
        font-size: $fs-14;
        color: $blue;
      }
    }
    &.filter-drowdown {
      width: 300px;
      padding: 10px;
      .filter-btns {
        text-align: right;
        margin-bottom: 10px;
        .btn-clear {
          background: #f3f3f7;
          border: 1px solid #f3f3f7;
          color: #42526e;
          font-size: 12px;
          margin-right: 5px;
          border-radius: 10px;
        }
        .btn-update {
          background: $purple2;
          border: 1px solid $purple2;
          color: $white;
          font-size: 12px;
          border-radius: 10px;
          &:hover {
            background-color: $lightpurple;
            border-color: $lightpurple;
          }
        }
      }
      .form-control {
        font-size: 13px;
        margin-bottom: 10px;
        color: $purple1;
        border-bottom: 1px solid #c9ced6;
        border-radius: 3px;
        padding: 4px 6px;
        box-shadow: none;
        cursor: pointer;
        background: url(../img/arrow-down-blue.svg);
        background-size: 9px;
        background-position: 96% 50%;
        background-repeat: no-repeat;
        option {
          text-transform: $capitalize;
        }
      }
    }
    .assignedUser {
      border: 1px solid $lightgray;
      position: $relative;
      padding: 4px 6px;
      border-radius: 3px;
      margin-bottom: 10px;
      background: url(../img/arrow-down-blue.svg);
      background-size: 9px;
      background-position: 96% 50%;
      background-repeat: no-repeat;
      cursor: pointer;
      p {
        color: $purple1;
        font-size: 13px;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
      }
      ul {
        padding: 0;
        box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15);
        position: $absolute;
        width: 100%;
        margin: 0;
        background-color: $white;
        // border: 1px solid #585858;
        border-radius: 0.25rem;
        z-index: 11;
        top: 30px;
        left: 0;
        display: none;
        max-height: 270px;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-thumb {
          background-color: #b9b9b9;
        }
        &.active {
          display: block;
        }
        li {
          list-style-type: none;
          color: $blue;
          font-size: 13px;
          // padding: 5px;
          cursor: pointer;
          line-height: 15px;
          small {
            font-size: 12px;
            color: #a5a5a5;
          }
          a {
            padding: 8px 22px;
            position: $relative;
            span.check-mark {
              position: $absolute;
              left: 3px;
              font-size: 13px;
              top: 7px;
            }
          }
        }
      }
    }
    .Anyclient {
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
      }
      ul {
        li {
          .dropdown-item {
            position: $relative;
            span {
              position: $absolute;
              left: 3px;
              font-size: 13px;
              top: 7px;
            }
          }
        }
      }
    }
  }
}
.dateBetween-filter {
  .form-control {
    background-image: none !important;
  }
  .date-input {
    width: 140px;
    padding-left: 6px;
    &::before {
      content: none;
    }
  }
}
.section-back {
  a {
    text-decoration: none;
    color: $lightgray1;
    font-size: $fs-14;
  }
}
.commonForm {
  .form-group {
    margin-bottom: 20px;
    &.itemGroup {
      label {
        text-transform: $uppercase;
        font-weight: 400;
        color: $lightgray1;
      }
    }
    label {
      color: $dark;
      display: inline-block;
      margin-bottom: 8px;
      font-size: $fs-14;
      font-weight: 600;
      &.checkbox-label {
        font-weight: 400;
      }
    }
    .form-check-input {
      box-shadow: none;
      cursor: pointer;
      &:checked {
        background-color: $primary;
        border-color: $primary;
      }
    }
    code {
      color: $blue;
      font-size: $fs-14;
    }
    .logHead {
      color: $lightgray1;
      margin: 0;
      font-size: $fs-14;
    }
    .logsData {
      color: $purple1;
      font-size: $fs-14;
    }
    .form-control {
      box-shadow: none;
      font-size: $fs-14;
      height: 40px;
      border-radius: 5px;
      &.mobileSpace {
        @media (max-width: 575px) {
          margin-bottom: 15px;
        }
      }
    }
    textarea {
      min-height: 100px;
    }
    .lp-copy-sel__control {
      min-height: 40px;
      border-radius: 5px;
    }
    input[type="checkbox"] {
      margin-right: 5px;
    }
    .tox-tinymce {
      border-radius: 5px;
    }
    select.form-control {
      background: url(../img/arrow-down-blue.svg);
      background-size: 11px;
      background-position: 98% 50%;
      background-repeat: no-repeat;
      color: $dark;
    }
    .css-1s2u09g-control {
      border-radius: 10px;
    }
    small {
      color: #6f6f6f;
      font-size: 12px;
      // font-weight: 500;
    }
    .preview {
      margin-bottom: 10px;
      img {
        width: 70px;
        height: 70px;
        object-fit: cover;
      }
    }
    .d-flex {
      .btn-default {
        background-color: #f3f3f7;
        border: 1px solid #f3f3f7;
        color: #42526e;
        margin-right: 5px;
        font-size: $fs-14;
        position: $relative;
        @media (max-width: 1440px) {
          font-size: 13px;
          padding: 4px 6px;
        }
        input {
          position: $absolute;
          left: 0;
          z-index: 1;
          opacity: 0;
          width: 100%;
        }
      }
    }
    input[type="checkbox"] {
      width: 1rem;
      height: 1rem;
      vertical-align: middle;
      background-color: #fff6ec;
      border: 1px solid grey;
    }
    &.checkbox-group {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      input[type="checkbox"] {
        vertical-align: unset;
      }
    }
    &.profilePassword {
      position: $relative;
      .form-control {
        background: #f0f0f0;
      }
      .groupIcon {
        position: $absolute;
        top: 40px;
        right: 10px;
        color: $purple1;
        cursor: pointer;
      }
    }
    &.profilePhotoSection {
      .d-flex {
        gap: 10px;
        button {
          height: 35px;
          border-radius: 10px;
        }
      }
    }
    .my-react-select {
      .css-14el2xx-placeholder {
        font-size: $fs-14;
      }
      .css-1s2u09g-control {
        min-height: 40px !important;
        border-radius: 5px;
        margin-bottom: 5px;
        &:focus {
          border-width: 1px;
        }
      }
    }
    .lp-copy-sel__value-container.lp-copy-sel__value-container--has-value.css-1d8n9bt {
      line-height: 16px;
      font-size: 14px;
    }
    .my-react-select {
      .lp-copy-sel__control {
        .lp-copy-sel__single-value {
          line-height: 15px;
          color: $purple1;
        }
      }
    }
    &.rolesSection {
      .css-t3ipsp-control {
        border-color: #ced4da;
        border-radius: 5px;
        box-shadow: none;
      }
      .css-13cymwt-control {
        border-radius: 5px;
      }
    }
    &.advanceOption {
      p {
        position: relative;
        border: 1px solid #dee2e6;
        border-radius: 5px;
        margin: 0;
        height: 150px;
        color: #6f6f6f;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        span {
          display: block;
          margin-bottom: 10px;
        }
        input {
          position: absolute;
          cursor: pointer;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    }
  }
  .expiryDate-field {
    position: $relative;
    z-index: 111;
  }
  .servicesTask {
    h4 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $dark;
      margin-bottom: 20px;
      .btn-add {
        background-color: $primary;
        color: $white;
        font-size: 14px;
        border-radius: 5px;
      }
    }
  }
  .btn-rule {
    background-color: #f3f3f7;
    border: 1px solid #f3f3f7;
    color: $purple1;
    border-radius: 10px !important;
    font-size: $fs-14;
    margin-left: 10px !important;
    padding: 0 10px;
    height: 40px;
  }
}
.table-bottom-option {
  .input-group {
    .form-control {
      border-color: $white;
      font-size: $fs-14;
      box-shadow: none;
      background-image: url(../img/arrow-down-blue.svg);
      background-repeat: no-repeat;
      background-size: 15px;
      background-position: 98% 50%;
      cursor: pointer;
    }
    .btn-apply {
      background-color: $white;
      border-left: 1px solid #f7f7f7;
      color: #6f798b;
      &:focus {
        border-color: $white;
        border-left: 1px solid #f7f7f7;
      }
    }
  }
}
.searchFilter {
  .form-control {
    width: 250px;
    margin-left: auto;
  }
}
.table {
  tr {
    th,
    td {
      vertical-align: middle;
      input[type="checkbox"] {
        width: 1rem;
        height: 1rem;
        box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.5);
        border: none;
      }
    }
  }
}

.dataDropdown {
  padding: 0;
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: $absolute;
  width: 100%;
  background-color: $white;
  z-index: 1;
  max-height: 260px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
  li {
    padding: 5px;
    list-style-type: none;
    cursor: pointer;
    font-size: $fs-14;
    &:hover {
      background-color: #f5f5f5;
    }
    span {
      font-size: 12px;
      color: #afafaf;
    }
  }
  &.AddDropdown {
    z-index: 999;
  }
}
.fw-500 {
  font-weight: 500;
}

// .p90-large {
//   @media (min-width: 1600px) and (max-width: 1920px) {
//     padding: 0 90px;
//   }
// }
.p55-large {
  @media (min-width: 1600px) and (max-width: 1920px) {
    padding: 0 55px;
  }
}
.p45-large {
  @media (min-width: 1600px) and (max-width: 1920px) {
    padding: 0 45px;
  }
}
.sectionPagination {
  display: flex;
  justify-content: space-between;
  align-items: $center;
  // padding: 0 10px;
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    font-size: 14px;
  }
  .paginate {
    float: none;
    margin-bottom: 10px;
  }
  select {
    border: 1px solid #888;
    width: 60px;
    height: 33px;
    margin-left: 10px;
    border-radius: 4px;
    background-color: $white;
    outline: none;
    cursor: pointer;
    margin-bottom: 10px;
    @media (max-width: 575px) {
      width: 50px;
      height: 27px;
      font-size: 13px;
    }
  }
  .page-item {
    .page-link {
      @media (max-width: 575px) {
        font-size: 13px;
      }
    }
  }
}
.lp-copy-sel__control--is-focused {
  border: 1px solid $lightgray !important;
  box-shadow: none !important;
}
.css-319lph-ValueContainer .css-6j8wv5-Input {
  margin: 0;
  padding: 0;
}
.css-319lph-ValueContainer .lp-copy-sel__single-value {
  line-height: $fs-16;
  font-size: $fs-14;
}
.ticketForm .relatedOrder .css-b62m3t-container {
  z-index: 11;
}
.ticketForm .ccGroup .css-b62m3t-container {
  z-index: 111;
}
.ticketForm
  .relatedOrder
  .my-react-select.my-react-select--compact.css-b62m3t-container {
  z-index: 99;
}
.cardNumber-section {
  padding: 0.375rem 0.75rem;
  border: 1px solid $lightgray;
  border-radius: 10px;
  box-shadow: none;
  height: 40px;
  background-color: $white;
  font-size: $fs-14;
  @media (max-width: 1366px) {
    height: 33px;
  }
}
.noData-section {
  text-align: center;
  p {
    margin: 0;
  }
  img {
    width: 100%;
    max-width: 150px;
  }
}
.activeProgress {
  display: flex;
  align-items: center;
}
.attachmentIcon {
  color: $primary;
}
.delIcon {
  color: $purple1;
}
.dashboardTop {
  background-color: $white;
  padding: 15px 0;
  border-bottom: 1px solid $lightgray3;
  .openArrowIcon {
    position: $fixed;
    border: 1px solid $lightgray3;
    border-radius: 50%;
    cursor: pointer;
    left: 205px;
    top: 55px;
    z-index: 999;
    &.toggle {
      left: 65px;
    }
    img {
      -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg);
      transition: all 0.5s ease;
    }
    &.rotate {
      img {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
      }
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
  .heading {
    h2 {
      font-size: clamp(1.2rem, 3vw, 2.2rem);
      display: flex;
      align-items: $center;
      .userCounter {
        color: #6f6f6f;
        font-size: $fs-16;
        border-left: 1px solid $lightgray3;
        padding: 10px;
        margin-left: 18px;
        font-weight: 500;
        @media screen and (max-width:575px) {
          margin-left: 10px;;
        }
      }
    }
  }
}
.heading {
  h2 {
    .btn-addOrder {
      background-color: $primary;
      color: $white;
      font-size: $fs-14;
      font-weight: $semi-bold;
      padding: 10px 15px;
      font-weight: 600;
      @media (max-width: 575px) {
        padding: 8px;
      }
    }
  }
}
.searchSection {
  display: flex;
  align-items: $center;
  justify-content: flex-end;
  gap: 10px;

  @media (max-width: 575px) {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;
    margin-left: 10px;
  }
  .form-group {
    span {
      position: $absolute;
      top: 10px;
      right: 10px;
    }
    .form-control {
      border-radius: 5px;
      padding: 10px 20px 10px 10px;
      height: 42px;
      box-shadow: none;
      border: 1px solid #d2d2d2;
    }
  }
  select {
    background-image: url(../img/icons/arrowIcon.svg);
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: 92% 50%;
    width: 70px;
  }
  .viewLink {
    text-decoration: none;
    color: $dark;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      list-style-type: none;
      color: $dark;
      font-weight: 500;
      margin-right: 10px;
      span {
        color: #6f6f6f;
      }
      button {
        background-color: transparent;
        border: none;
        svg {
          path {
            fill: #6f6f6f;
          }
        }
        &:hover {
          svg {
            path {
              fill: $dark;
            }
          }
        }
      }
    }
  }
}
.commonBtn-section {
  .btn {
    width: 100%;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px 10px;
    font-weight: 700;
    &.btn-save {
      background: $primary;
      color: $white;
    }
    &.btn-cancel {
      background-color: #dee2e6;
      color: $dark;
    }
  }
}

.outerBorder {
  border: 1px solid #dee2e6;
}

// common css ends

// permission denied starts
.section-sermissionDenied {
  text-align: $center;
  h1 {
    font-weight: $semi-bold;
    color: $blue;
  }
  p {
    color: #777;
  }
  a {
    // color: #007bff;
    background-color: #007d87;
    color: $white;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    &:hover {
      background-color: #09949e;
      border-color: #09949e;
    }
  }
}
// permission denied ends

// login section starts
.section-login {
  .h-100vh {
    @media (max-width: 575px) {
      height: auto;
    }
  }
  .formOrder {
    @media (max-width: 991px) {
      order: 2;
    }
  }
  .loginForm {
    padding: 30px 80px;
    @media only screen and (max-width: 1199px) {
      padding: 20px;
    }
    a.brandName {
      color: $dark;
      font-weight: 700;
      margin: 0;
      width: 100%;
      text-decoration: none;
      display: inline-block;
      text-align: $center;
      font-size: 25px;
      img {
        width: 100%;
        max-width: 200px;
        @media (max-width: 575px) {
          max-width: 150px;
        }
      }
    }
    h3 {
      margin-top: 30px;
      color: $dark;
      font-size: 25px;
      margin-bottom: 30px;
      font-weight: $semi-bold;
      text-align: $center;
      @media (max-width: 575px) {
        font-size: 21px;
      }
    }
    form {
      .form-floating {
        position: $relative;
        .form-control {
          height: 48px;
          box-shadow: none;
          border-radius: 5px;
          border: 1px solid #dcdcdc;
          @media (max-width: 575px) {
          }
          &:focus {
            border-color: $lightgray;
          }
        }
        label {
          font-size: $fs-14;
          padding-top: 17px;
          opacity: 0.6;
        }
        .formIcon {
          position: $absolute;
          top: 10px;
          right: 15px;
        }
        .eyeIcon {
          position: $absolute;
          top: 15px;
          right: 15px;
          font-size: 12px;
          cursor: pointer;
          color: $primary;
        }
        small.error {
          color: #f00;
        }
      }
      .form-group {
        .form-control {
          height: 48px;
          box-shadow: none;
          border-radius: 5px;
        }
        select {
          background-image: url(../img/arrow-down-blue.svg);
          background-size: 16px;
          background-repeat: no-repeat;
          background-position: 95% 50%;
          &:focus {
            border-color: $lightgray;
          }
        }
      }
      .input-group {
        .btn-submit {
          width: 100%;
          height: 48px;
          background-color: $primary;
          border-color: $primary;
          border-radius: 5px;
          font-weight: $semi-bold;
          &:hover {
            background-color: #047078;
            border-color: #047078;
          }
        }
      }
      &.formLogin {
        margin-top: 30px;
        // background: $white;
        border-radius: 5px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        @media (max-width: 575px) {
          margin-top: 10px;
        }
        h3 {
          // background: $purple1;
          color: $dark;
          font-size: 22px;
          margin: 0;
          padding: 15px 0;
          border-radius: 5px 5px 0 0;
          @media (max-width: 575px) {
            font-size: 18px;
            padding: 10px 0;
          }
        }
        .formInner {
          padding: 30px 30px;
          @media (max-width: 575px) {
            padding: 20px 0;
          }
        }
      }
      .RememderSection {
        display: flex;
        justify-content: space-between;
        align-items: $center;
        margin: 30px 0;
        .form-check {
          .form-check-label {
            color: #7a7a7a;
            font-size: $fs-14;
          }
          .form-check-input {
            border-color: #007d87;
            border-radius: 0;
            box-shadow: none;
            &:checked {
              background-color: #007d87;
            }
          }
        }
      }
    }
    .forgotLink {
      text-decoration: none;
      color: $primary;
      font-size: 13px;
      font-weight: $semi-bold;
      text-decoration: underline;
      &.btn-backSignin {
        background-color: $primary;
        color: $white;
        padding: 8px 20px;
        border-radius: 5px;
        text-decoration: none;
        // &:hover {
        //   background-color: #4c3dd9;
        //   border-color: #4c3dd9;
        // }
      }
    }
    .signupText {
      text-align: $center;
      font-size: $fs-14;
      color: $gray;
      margin: 10px 0 0 0;
      a {
        text-decoration: none;
        color: $primary;
      }
    }
    &.logoutLayout {
      p {
        img {
          width: 100%;
          max-width: 30px;
          margin-right: 10px;
        }
      }
    }
  }
  .loginImg {
    text-align: $center;
    @media (max-width: 575px) {
      margin: 20px 0;
    }
    img {
      width: 100%;
      max-width: 70%;
      &.imgForgotpass {
        max-width: 50%;
      }
      @media (max-width: 991px) {
        max-width: 70%;
      }
      @media (max-width: 575px) {
        max-width: 60%;
      }
    }
  }
  .loaderText {
    font-size: 12px;
  }
  .sectionalert {
    background-color: rgba(255, 248, 229, 1);
    font-size: $fs-14;
    border-left: 4px solid #ffa500;
    text-align: $center;
    padding: 10px;
    border-radius: 5px;
    p {
      color: #6c757d;
      margin: 0;
    }
  }
  .formGrid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    > * {
      width: 100%;
    }
    .loginForm {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      @media only screen and (max-width: 1600px) {
        max-width: 100%;
      }
    }
    .loginForm,
    .formFooter {
      margin-top: auto;
    }
    .formFooter {
      padding-bottom: 30px;
    }
    .formContent {
      padding-left: 15%;
      padding-right: 5%;
      margin-bottom: 40px;
      @media only screen and (max-width: 991px) {
        max-width: 70%;
        padding: 30px 0;
        margin: 0 auto;
        text-align: center;
      }
      @media only screen and (max-width: 575px) {
        max-width: 100%;
      }
      h3 {
        font-size: 30px;
        color: $dark;
        font-weight: 700;
        @media only screen and (max-width: 575px) {
          font-size: 26px;
        }
      }
      p {
        font-size: 15px;
        color: $dark;
        font-weight: 400;
        line-height: 22px;
        @media only screen and (max-width: 575px) {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
    .border {
      border-width: 0 0 0 1px;
      border-style: solid;
      border-color: $grayTint3;
      @media (max-width: 575px) {
        border-width: 0 0 1px;
      }
    }
  }
  .copyrightText {
    text-align: $center;
    margin: 10px 0 0 0;
    font-size: 13px;
    color: $black;
    opacity: 0.5;
  }
}
// login section ends

// sidebar starts
.sectionSidebar {
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  left: 0;
  width: 230px;
  padding: 0;
  position: $fixed;
  top: 0;
  transition: all 0.3s;
  z-index: 100;
  background-color: $white;
  // background-image: linear-gradient(to bottom, #5a67bd 0, #004a99 100%);
  // background-image: linear-gradient(-45deg, #3d005a, #8a00cb);
  transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
  padding: 15px 0;
  border-right: 1px solid $lightgray3;
  @media (max-width: 991px) {
    width: 90px;
    // transform: translate(-150px, 0);
    .navlinksText,
    .menuHeading {
      display: none;
    }
    .logo {
      a {
        .company-name {
          display: none;
        }
      }
      // .logoMain {
      //   display: none;
      // }
      .logoMini {
        display: inline-block;
      }
    }
    .sidebarInner {
      .iconCross {
        display: none;
      }
      ul {
        li {
          text-align: center;
          a {
            display: inline-block;
            text-align: center;
            border-radius: 6px;
            .navlinksIcon {
              width: auto;
            }
          }
        }
      }
    }
    .quickBuildApplications {
      display: none;
    }
  }

  .sidebarInner {
    position: $relative;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }
    .iconCross {
      position: $absolute;
      top: -10px;
      right: 0;
      display: none;
      @media (max-width: 991px) {
        display: none;
        top: 0;
        right: 10px;
      }
      i {
        color: $dark;
      }
    }
    .logo {
      text-align: center;
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        justify-content: center;
        color: #000000;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 0 12px;
        .company-logo {
          width: 100%;
          img {
            max-width: 100%;
            height: auto;
          }
        }
        .company-name {
          width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .logoMini {
        display: none;
      }
    }
    h4 {
      text-align: $center;
      a {
        text-decoration: none;
        color: #242525;
        font-weight: 700;
        padding: 1rem 0;
        text-align: left;
        width: 100%;
        font-size: 18px;
        img.logo {
          width: 100%;
          max-width: 120px;
        }
        &:hover {
          color: $white;
        }
      }
    }
    ul {
      margin: 30px 0;
      padding: 0;
      li {
        position: relative;
        display: block;
        list-style-type: none;
        text-align: left;
        color: $lightgray2;
        font-size: 14px;
        margin-bottom: 5px;
        // margin-bottom: 5px;
        &.menuHeading {
          margin: 20px 0 10px;
          padding: 0 10px;
          font-weight: $bold;
        }
        a {
          color: #242525;
          text-decoration: none;
          padding: 12px 15px;
          display: block;
          font-size: 16px;
          // border-radius: 4px;
          font-weight: $semi-bold;
          transition: all 0.3s ease-in-out;
          .navlinksIcon {
            // opacity: 1;
            width: 21px;
            display: inline-block;
            img {
              width: 100%;
            }
            svg {
              path {
                fill: #242525;
                transition: all 0.3s ease-in-out;
              }
            }
          }
          .navlinksText {
            margin-left: 10px;
          }
          &:hover {
            background-color: #d9eced;
            color: $primary;
            svg {
              path {
                fill: $primary;
              }
            }
            .strokeSection {
              svg {
                path {
                  fill: none;
                  stroke: $primary;
                }
              }
            }
          }
          .strokeSection {
            svg {
              path {
                fill: none;
                stroke: #242525;
              }
            }
          }
        }
        &.active {
          a {
            background-color: #d9eced;
            color: $primary;
            svg {
              path {
                fill: $primary;
              }
            }
            .strokeSection {
              svg {
                path {
                  fill: none;
                  stroke: $primary;
                }
              }
            }
          }
        }
      }
    }
  }
  &.sidebarActive {
    width: 90px;
    // transform: translate(-150px, 0);
    .navlinksText,
    .menuHeading {
      display: none;
    }
    .logo {
      a {
        .company-name {
          display: none;
        }
      }
      .logoMain {
        display: none;
      }
      .logoMini {
        display: inline-block;
      }
    }
    .sidebarInner {
      ul {
        li {
          text-align: center;
          a {
            display: inline-block;
            border-radius: 6px;
            .navlinksIcon {
              width: auto;
            }
          }
        }
      }
    }
    .quickBuildApplications {
      display: none;
    }
    @media (max-width: 991px) {
      .sidebarInner {
        .iconCross {
          display: block;
          padding: 10px;
        }
      }
      bottom: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      left: 0;
      width: 230px;
      padding: 0;
      position: $fixed;
      top: 0;
      transition: all 0.3s;
      z-index: 100;
      background-color: $white;
      // background-image: linear-gradient(to bottom, #5a67bd 0, #004a99 100%);
      // background-image: linear-gradient(-45deg, #3d005a, #8a00cb);
      transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
      padding: 15px 0;
      border-right: 1px solid $lightgray3;
      .navlinksText,
      .menuHeading {
        display: inline-block !important;
      }
      .logo {
        a {
          .company-name {
            display: none;
          }
        }
        // .logoMain {
        //   display: none;
        // }
        .logoMini {
          display: inline-block;
        }
      }
      .sidebarInner {
        ul {
          li {
            text-align: center;
            a {
              display: block !important;
              text-align: left !important;
              border-radius: 6px;
              .navlinksIcon {
                width: auto;
              }
            }
          }
        }
      }
      .quickBuildApplications {
        display: none;
      }
    }
  }

  .quickBuildApplications {
    padding: 15px;
    .quickBuildApplicationsInner {
      background-color: $yellowTint1;
      border-radius: 20px;
      padding: 15px;
      text-align: center;
      h4 {
        font-size: 24px;
        color: #242525;
        font-weight: $bold;
      }
      p {
        font-size: 12px;
        color: #242525;
      }
      .commonButton {
        width: 100%;
      }
    }
  }
  .contact-link {
    .href-link {
      text-decoration: none;
      color: #007d87;
    }
  }
}

.commonButton {
  background-color: #007d87;
  display: block;
  font-size: $fs-14;
  color: $white;
  font-weight: $semi-bold;
  border: 1px solid #007d87;
  padding: 10px 15px;
  font-weight: 600;
  border-radius: 8px;
}

.commonSlidePl {
  @media (max-width: 991px) {
    padding-left: 90px;
  }
  padding-left: 230px;
  transition: all 0.5s ease;
  &.toggle {
    padding-left: 90px;
  }
}

// sidebar ends

// topbar starts
.topbar {
  position: $relative;
  background-color: $white;
  border-bottom: 1px solid $lightgray3;
  padding-top: 12px;
  padding-bottom: 12px;
  @media (max-width: 991px) {
    padding-left: 0;
  }
  .search-parent {
    input {
      margin-left: 10px;
    }
    align-items: center;
    @media (max-width: 991px) {
      display: flex;
    }
    @media (max-width: 575px) {
      margin-bottom: 10px;
    }
  }
  .hamburger {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
    @media (max-width: 575px) {
      margin-right: 10px;
    }
    img {
      width: 100%;
      max-width: 25px;
    }
  }
  .form-group {
    position: $relative;
    // width: 300px;
    .form-control {
      // width: 300px;
      height: 40px;
      border: 0;
      font-size: $fs-14;
      box-shadow: none;
      border: 1px solid $lightgray3;
      border-radius: 5px;
      cursor: pointer;
      @media (max-width: 991px) {
        width: 250px;
      }
      @media (max-width: 575px) {
        width: 100%;
      }
    }
    span {
      position: $absolute;
      top: 6px;
      right: 6px;
      color: #b3bac5;
    }
  }
  .helloText {
    @media only screen and (max-width: 991px) {
      margin-top: 11px;
    }
    h3 {
      font-size: 18px;
      font-weight: 700;
      margin: 0;
      color: $dark;
    }
    p {
      color: #6f6f6f;
      font-size: 12px;
      margin: 0;
    }
  }
  .sidemenus {
    @media only screen and (max-width: 991px) {
      margin-top: 12px;
    }
    ul {
      padding: 0;
      text-align: right;
      margin: 0;
      li {
        list-style-type: none;
        display: inline-block;
        margin: 0 15px;
        a {
          color: #8f99a9;
          text-decoration: none;
        }
        .dropdown {
          .notification {
            margin-right: 20px;
            @media (max-width: 575px) {
              margin-right: 10px;
            }
          }
          button {
            background-color: transparent;
            border: none;
            padding: 0;
            color: $dark;
            font-weight: $semi-bold;
            &::after {
              display: none;
            }
            span {
              @media (max-width: 575px) {
                display: none;
              }
            }
            img {
              width: 100%;
              max-width: 35px;
              height: 35px;
              object-fit: cover;
              border-radius: 50%;
              margin-left: 5px;
              &.arrowImg {
                width: 13px;
                height: 13px;
                opacity: 0.5;
              }
            }
          }
          .dropdown-menu {
            li {
              text-align: left;
              display: block;
              margin: 0;
              a {
                display: block;
                padding: 5px 10px;
                color: $blue;
                font-size: $fs-14;
                &:hover {
                  background: $grayTint1;
                  color: $dark !important;
                }
              }
              &:first-child {
                a {
                  border-radius: 0.375rem 0.375rem 0 0;
                }
              }
              &:last-child {
                a {
                  border-radius: 0 0 0.375rem 0.375rem;
                }
              }
            }
            hr {
              margin: 5px 0;
            }
          }
        }
      }
    }
  }
}
// topbar ends

// orders section starts
.orders-section {
  .common-tabs {
    margin-top: 10px;
    .nav {
      .nav-item {
        .nav-link {
          color: #6f6f6f;
          outline: none;
          font-size: $fs-14;
          padding: 10px 0;
          margin-right: 10px;
          @media (max-width: 1536px) {
            margin-bottom: 10px;
          }
          @media (max-width: 1440px) {
            padding: 5px 10px;
          }
          @media (max-width: 991px) {
            padding: 5px 9px;
          }
          @media (max-width: 575px) {
            padding: 5px 7px;
          }
          span {
            margin-left: 5px;
          }
          &.active {
            border-bottom: 2px solid $primary;
            background-color: transparent;
            border-radius: 0;
            color: $dark;
            font-weight: $semi-bold;
          }
        }
      }
    }
    .tab-content {
      .tab-pane {
        outline: none;
      }
    }
    &.orderTabs {
      .nav-pills {
        @media (max-width: 767px) {
          display: block;
          white-space: nowrap;
          overflow: auto;
          .nav-item {
            display: inline-block;
          }
        }
      }
    }
  }
  .common-table {
    background-color: $white;
    .table {
      &.logs-table {
        tr {
          td {
            a {
              text-decoration: none;
              color: $lightgray1;
              &.log-link {
                color: $purple1;
              }
            }
          }
        }
      }
      tr {
        th {
          color: $dark;
          font-size: $fs-15;
        }
        td {
          text-align: left;
          color: $lightgray1;
          text-transform: $uppercase;
          font-weight: 500;
          font-size: $fs-15;
          padding: 0.75rem;
          a {
            text-decoration: none;
            color: $dark;
          }
          .orderStatus {
            font-size: 12px;
          }
          .form-group {
            display: block;
          }
          .clientSection {
            display: flex;
            gap: 10px;
            .img {
              img {
                width: 40px;
                max-width: 40px;
                height: 40px;
                border-radius: 50%;
                object-fit: cover;
              }
            }
            .clientName {
              p {
                margin: 0;
                text-transform: $capitalize;
                a {
                  color: $dark;
                  font-size: $fs-14;
                  text-decoration: none;
                }
                &.clientEmail {
                  text-transform: none;
                }
              }
            }
          }
          .ticketDetail {
            p {
              margin-bottom: 0;
              font-weight: 400;
              a {
                color: $dark;
                text-decoration: none;
                font-weight: $semi-bold;
                &.ticketClientEmail {
                  text-transform: none;
                }
              }
              span {
                font-size: 11px;
              }
            }
          }
          .serviceDescription {
            p {
              margin: 0;
            }
          }
          .dropdown {
            .dropdown-toggle {
              i {
                color: #6d788d;
              }
            }
          }
        }
        td {
          text-transform: $capitalize;
        }
        .clientDetail {
          p {
            margin-bottom: 0;
            a {
              text-decoration: none;
              color: $dark;
              font-weight: $semi-bold;
            }
            span {
              background-color: #f3f3f7;
              font-size: 11px;
              color: $lightgray1;
              font-weight: 400;
              padding: 2px 5px;
              border-radius: 5px;
              i {
                margin-right: 5px;
              }
            }
            &.subject {
              a {
                color: $lightgray1;
                font-weight: 400;
                text-transform: $capitalize;
                font-size: $fs-14;
              }
            }
          }
        }
        .assignedImg {
          // text-align: right;
          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-left: -8px;
          }
        }
        &:last-child {
          td {
            border: none;
          }
        }
      }
    }
    &.invoiceTable {
      @media (max-width: 767px) {
        overflow: auto;
      }
    }
  }
  .pagination {
    margin-top: 15px;
    @media (max-width: 575px) {
      margin: 0;
      display: inline-flex;
      flex-wrap: wrap;
    }
    .page-item {
      .page-link {
        color: #6f6f6f;
        box-shadow: none;
        padding: 3px 6px;
        font-weight: 500;
        border-color: #eff0f4;
        i {
          font-size: 13px;
        }
      }
      &.active {
        .page-link {
          background-color: $white;
          color: $primary;
          border-color: #eff0f4;
        }
      }
    }
  }
}
// order section ends

// order modal starts
.add-order-modals {
  .modal-header {
    .modal-title {
      color: $dark;
      font-weight: $semi-bold;
      font-size: 20px;
      font-weight: $semi-bold;
    }
    .btn-close {
      background-size: 11px;
      box-shadow: none;
    }
  }
  .add-order-form {
    .add-alert {
      background-color: #fff6ec;
      // border-left: 6px solid #ffc107;
      color: $lightgray1;
      border: 0.25rem 0 0 0.25rem;
      text-align: left;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 10px;
      p {
        font-size: $fs-14;
        margin: 0;
      }
    }
    form {
      .form-group {
        text-align: left;
        margin-bottom: 10px;
        label {
          color: $dark;
          font-weight: $semi-bold;
          font-size: $fs-14;
          margin-bottom: 0.5rem;
        }
        .form-control {
          color: $blue;
          font-size: $fs-14;
          box-shadow: none;
          &:focus {
            border-color: $lightgray;
          }
        }
        .css-1s2u09g-control {
          border-radius: 10px;
        }
        select {
          background: url(../img/arrow-down-blue.svg);
          background-repeat: no-repeat;
          background-size: 15px;
          background-position: 97% 50%;
        }
        .upload-file {
          text-align: $center;
          border: 2px dashed #d7dbe3;
          position: $relative;
          padding: 20px;
          i {
            color: #d7dbe3;
          }
          p {
            font-size: $fs-14;
            color: $lightgray1;
            margin: 0;
            span {
              color: #007bff;
            }
          }
          .inputfile {
            position: $absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
          }
        }
        small {
          color: $lightgray1;
          font-size: 13px;
          a {
            text-decoration: none;
          }
        }
        .lp-copy-sel__control {
          height: 40px;
          border-radius: 5px;
        }
        .lp-copy-sel__single-value.css-qc6sy-singleValue {
          line-height: 16px;
          font-size: 14px;
        }
        .my-react-select {
          box-shadow: none;
          outline: none;
          .css-13cymwt-control {
            border-radius: 5px;
          }
          .css-t3ipsp-control {
            border-color: #ccc;
            border-radius: 5px;
            &:hover {
              border-color: #ccc;
              box-shadow: none;
            }
          }
        }
        .lp-copy-sel__control {
          .lp-copy-sel__single-value {
            line-height: 15px;
          }
        }
      }
    }
  }
  .add-taxRule-form {
    .form-group {
      label {
        font-size: $fs-14;
        color: $purple1;
        font-weight: $semi-bold;
      }
      .form-control {
        border-radius: 10px;
        box-shadow: none;
      }
      select {
        background-image: url(../img/arrow-down-blue.svg);
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: 97% 50%;
      }
      .input-group-prepend {
        span {
          border-radius: 0 10px 10px 0;
        }
      }
    }
  }
  .modal-footer {
    border: 0;
    button {
      font-size: $fs-14;
      border-radius: 5px;
      width: 100%;
      max-width: 110px;
      font-weight: $semi-bold;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
      &.close-btn {
        background-color: $lightgray3;
        border-color: $lightgray3;
        color: $dark;
      }
      &.btn-primary {
        background-color: $primary;
        border-color: $primary;
        &:hover {
          background-color: #04747c;
          border-color: #04747c;
        }
      }
      &.cancel-btn {
        background-color: $lightgray3;
        border-color: $lightgray3;
        color: $dark;
      }
    }
  }
  &.newTicket-modal {
    .add-order-form {
      .form-group {
        margin-bottom: 15px;
        label {
          color: $purple1;
          display: inline-block;
          margin-bottom: 8px;
          font-size: $fs-14;
          font-weight: 500;
        }
        .form-control {
          box-shadow: none;
          border-radius: 10px;
        }
        .relatedOrder {
          z-index: 11;
        }
      }
    }
  }
}
// order modal ends

// task modal start
.taskModal {
  .modal-body {
    form {
      .form-group {
        margin-bottom: 10px;
        label {
          color: $dark;
          display: inline-block;
          margin-bottom: 8px;
          font-size: $fs-14;
          font-weight: $semi-bold;
        }
        .form-control {
          box-shadow: none;
          font-size: $fs-14;
          height: 40px;
          border-radius: $fs-10;
        }
        textarea {
          height: 70px !important;
        }
      }
    }
  }
  .modal-footer {
    .btn-primary {
      background-color: $primary;
      color: $white;
      border-color: $primary;
      border-radius: 5px;
    }
  }
}
// task modal ends

// search modal starts
.searchModal {
  @keyframes blowUpModal {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  animation: blowUpModal 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  .modal-header {
    background-color: #f9f9f9;
    .input-group {
      position: $relative;
      .form-control {
        height: 45px;
        box-shadow: none;
        border-radius: 10px !important;
        padding-left: 30px;
        font-size: 14px;
        @media (max-width: 575px) {
          height: 39px;
        }
        &:focus {
          border-color: $lightgray;
        }
      }
      span {
        position: $absolute;
        top: 10px;
        left: 10px;
        z-index: 11;
        i {
          color: #b3bac5;
        }
      }
    }
    .btn-close {
      background-color: $primary;
      background-image: url(../img/crossIcon.svg);
      background-size: 11px;
      box-shadow: none;
      opacity: 1;
      border-radius: 50%;
      width: 13px;
      height: 13px;
      @media (max-width: 575px) {
        width: 12px;
        height: 10px;
      }
    }
  }
  .searchDetail-section {
    .searchButtons {
      .btn {
        box-shadow: none;
        border-radius: 0;
        border-color: $white;
        color: $purple1;
        padding: 4px 10px;
        font-weight: $semi-bold;
        margin-right: 7px;
        &.active {
          border-bottom: 2px solid $purple1;
        }
        @media (max-width: 575px) {
          font-size: 13px;
          padding: 4px 8px;
          margin-right: 3px;
        }
      }
    }
    .common-tabs {
      .nav-pills {
        margin-bottom: 30px;
        .nav-link {
          color: #6f6f6f;
          padding: 5px 9px;
          border-bottom: 2px solid #fff;
          border-radius: 0;
          @media (max-width: 575px) {
            padding: 4px 8px;
            font-size: 14px;
          }
          &.active {
            color: $dark;
            border-color: $primary;
            background-color: transparent;
          }
        }
      }
      .table {
        tr {
          th {
            color: $dark;
            font-size: $fs-15;
            text-transform: $capitalize;
            font-weight: 500;
            @media (max-width: 575px) {
              white-space: nowrap;
            }
          }
          td {
            font-size: $fs-14;
            color: $lightgray1;
            border-bottom: 1px solid $lightgray3;
            @media (max-width: 575px) {
              white-space: nowrap;
            }
            .clientSection {
              display: inline-flex;
              .img {
                margin-right: 20px;
                img {
                  width: 100%;
                  max-width: 40px;
                  border-radius: 50%;
                }
              }
              .clientName {
                p {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
    .sectionPagination {
      label {
        color: #6b778c;
      }
      select {
        border-color: #ced4da;
        color: $lightgray1;
      }
      .pagination {
        .page-item {
          .page-link {
            color: $purple1;
          }
          &.active {
            .page-link {
              background-color: $purple1;
              color: $white;
              border-color: $purple1;
            }
          }
        }
      }
    }
  }
}
// search modal ends

// signup modal starts
.signupModal {
  .modal-content {
    .modal-header {
      border: none;
      padding: 0;
      .btn-close {
        background-color: #007d87;
        color: $white;
        opacity: 1;
        background-image: url(../img/crossIcon.svg);
        background-size: 10px;
        background-repeat: no-repeat;
        border-radius: 50%;
        margin: 10px 10px 0 auto;
      }
    }
    .signupModal-content {
      text-align: center;
      img {
        width: 100%;
        max-width: 300px;
        margin-bottom: 10px;
      }
      h6 {
        font-size: 25px;
        color: #007d87;
      }
      .btn {
        background-color: #007d87;
        color: $white;
        border: 1px solid #007d87;
        margin-top: 10px;
        border-radius: 10px;
      }
    }
  }
}
// signup modal ends

// view orders starts
.viewOrders {
  &.horizontalPadding {
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  .sectionHeading {
    margin-bottom: 20px;
    h3 {
      font-size: 21px;
      color: $dark;
      font-weight: $semi-bold;
      margin-bottom: 20px;
      text-transform: $capitalize;
      span.editHeading {
        background: #fafafd;
        border: 1px solid #fafafd;
        color: #42526e;
        display: inline-block;
        font-size: 0.81rem;
        padding: 6px 8px;
        border-radius: 3px;
        opacity: 0;
        transform: translate(-5px);
        transition: all 0.2s linear;
        cursor: pointer;
        margin-left: 5px;
      }
      &:hover {
        .editHeading {
          opacity: 1;
          transform: translate(0);
        }
      }
    }
  }
  .teamNote {
    border: 1px solid #dee2e6;
    border-left: 3px solid #007d87;
    background: $white;
    border-radius: 5px;
    padding: 15px;
    position: $relative;
    p {
      margin: 0;
      font-size: $fs-14;
    }
    a {
      text-decoration: none;
      position: $absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      color: #6f6f6f;
    }
    .form-group {
      textarea {
        border: none;
        box-shadow: none;
        resize: none;
      }
      .btn-save-note {
        font-size: $fs-14;
        margin-top: 10px;
      }
    }
  }
  .projectData {
    .sectionHeading {
      display: flex;
      justify-content: space-between;
      align-items: $center;
      .dropdown-toggle {
        background: $white;
        box-shadow: 0 1px 1px 0 #dfe1e6;
        color: #333;
      }
    }
    .projectdataContent {
      background-color: $white;
      padding: 20px;
      border-radius: 5px;
      p {
        a {
          text-decoration: none;
        }
      }
      .btnShowmore {
        display: block;
        text-align: $center;
        text-decoration: none;
        border-top: 1px solid $purple2;
        padding: 6px 0 0 0;
        color: $purple1;
      }
      .questionSection {
        h3 {
          color: $purple1;
          font-size: $fs-16;
          font-weight: $semi-bold;
          margin-bottom: 3px;
        }
        p {
          color: $purple1;
        }
        img {
          margin-bottom: 10px;
        }
      }
    }
    .orderBtn {
      background-color: $primary;
      color: $white;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 600;
      padding: 10px 20px;
      @media screen and (max-width:575px) {
        padding: 9px;
      }
    }
  }
  .sectionMessage {
    h3 {
      font-size: 21px;
      color: $dark;
      font-weight: $semi-bold;
      margin-bottom: 20px;
    }
    .clientMessage {
      background-color: $white;
      padding: 20px;
      box-shadow: inset 3px 0 0 #b3bac5;
      border-bottom: 1px solid #ddd;
      &:last-child {
        border-bottom: none;
      }
      &.grey {
        box-shadow: inset 3px 0 0 #b3bac5;
      }
      &.yellow {
        box-shadow: inset 3px 0 0 #ffc107;
      }
      &.white {
        box-shadow: inset 3px 0 0 $white;
      }
      .messageUser {
        display: flex;
        align-items: $center;
        gap: 15px;
        .userImg {
          img {
            // width: 100%;
            width: 45px;
            height: 45px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .userName {
          h4 {
            font-weight: $semi-bold;
            font-size: 15px;
            &.client {
              color: $lightgray1;
            }
            &.team {
              color: #ffc107;
            }
            .messageStatus {
              background: $primary;
              color: $white;
              font-weight: 400;
              margin-left: 5px;
              padding: 2px 5px;
              font-size: 12px;
              border-radius: 5px;
            }
          }
          p {
            color: $lightgray1;
            margin: 0;
            font-size: 12px;
          }
        }
        &.messageTiming {
          justify-content: flex-end;
          @media (max-width: 767px) {
            justify-content: flex-start;
          }
        }
      }
      .dropdown {
        .dropdown-toggle {
          background: $white;
          border: 0;
          box-shadow: 0 1px 1px 0 #dfe1e6;
          &::after {
            display: none;
          }
        }
        .dropdown-menu {
          .dropdown-item {
            font-size: $fs-14;
            color: $blue;
          }
        }
      }
      .message-content {
        padding-left: 50px;
        margin-top: 10px;
        @media (max-width: 575px) {
          padding-left: 0;
        }
        p {
          font-size: $fs-14;
          color: $dark;
        }
      }
    }
    .messageAttachment {
      #addedfiles {
        display: flex;
        margin-bottom: 6px;
      }
      a {
        color: $purple1;
        display: block;
        font-size: $fs-14;
        @media (max-width: 575px) {
          word-break: break-all;
        }
      }
      .innerIcon {
        color: #8d8d8d;
        i {
          font-size: 13px;
        }
        &.attachIcon {
          color: #bdbdbd;
        }
        &.crossIcon {
        }
      }
    }
    .reply-section {
      text-align: right;
      margin-top: 10px;
      @media screen and (max-width:575px) {
        text-align: left;
      }
      .btn {
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
        color: #42526e;
        font-size: $fs-14;
        font-weight: 600;
        padding: 10px 20px;
        border: 1px solid $primary;
        border-radius: 5px;
        margin: 0 0 0 5px;
        @media (max-width: 575px) {
          padding: 6px 5px;
          font-size: 13px;
        }
        &.replyBtn {
          color: $primary;
        }
        &.teamBtn {
          background-color: $primary;
          color: $white;
        }
      }
      form {
        background-color: $white;
        padding: 15px;
        textarea {
          border: 0;
          resize: none;
          margin-bottom: 10px;
        }
        .delete {
          background: $white;
          box-shadow: 0 1px 1px 0 #dfe1e6;
          color: $purple1;
          font-size: $fs-14;
          padding: 0.375rem 0.75rem;
        }
        .send-btn {
          display: flex;
          justify-content: end;
          @media (max-width: 767px) {
            margin-top: 10px;
          }
          .btn {
            background-color: $primary;
            border-color: $primary;
            color: $white;
            border-radius: 10px;
          }
          select {
            width: 200px;
            background: url(../img/arrow-down-blue.svg) #f3f3f7;
            color: #42526e;
            background-size: 9px;
            background-position: 96% 50%;
            background-repeat: no-repeat;
            box-shadow: none;
            margin-right: 10px;
            border-radius: 10px;
            @media (max-width: 1366px) {
              width: 170px;
            }
            @media (max-width: 767px) {
              width: 180px;
            }
          }
        }
      }
      .ccText {
        font-size: 13px;
        text-align: left;
        margin-top: 10px;
        a {
          text-decoration: none;
          &.ccIds {
            line-break: anywhere;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .emojisection {
    text-align: $center;
    margin-bottom: 20px;
    h4 {
      color: $blue;
      margin-bottom: 20px;
    }
    .emojibutton {
      img {
        width: 100%;
        max-width: 40px;
      }
      button {
        color: #0d6efd;
        font-size: $fs-14;
        border: 0;
        margin: 0 10px;
        background-color: transparent;
      }
    }
  }
  .section-history {
    border: 1px solid #dee2e6;
    h3 {
      font-size: 21px;
      color: $dark;
      font-weight: $semi-bold;
      margin-bottom: 20px;
    }
    .history-table {
      background-color: $white;
      padding: 20px;
      .table {
        tr {
          td {
            color: $lightgray1;
            font-size: $fs-14;
            padding: 10px 20px;
            border-bottom: 1px solid #dee2e6;
            @media (max-width: 575px) {
              font-size: $fs-12;
            }
            .clientName {
              p {
                margin: 0;
                color: $dark;
                font-size: $fs-14;
                // cursor: pointer;
                @media (max-width: 575px) {
                  font-size: $fs-12;
                }
                span {
                  background-color: #f3f3f7;
                  color: $lightgray1;
                  padding: 5px;
                }
              }
            }
          }
        }
      }
      .btnShowmore {
        display: block;
        text-align: $center;
        text-decoration: none;
        padding: 6px 0 0 0;
        color: $lightgray2;
        font-weight: $semi-bold;
        margin-top: 20px;
        @media (max-width: 575px) {
          font-size: $fs-12;
        }
      }
    }
  }
  .status-btns {
    .status-btn {
      width: 120px;
      text-align: left;
      background-image: url(../img/greyArrow.svg);
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: 96% 50%;
      @media (max-width: 575px) {
        width: 88px;
      }
    }
    .btn-follow {
      background-color: $white;
      border: 1px solid #d2d2d2;
      box-shadow: 0 1px 1px 0 #dfe1e6;
      color: $dark;
      font-size: $fs-14;
      padding: 0.55rem 1.2rem 0.55rem 0.75rem;
      margin-left: 8px;
      // @media (max-width: 575px) {
      //   width: 46%;
      // }
      span {
        margin-right: 5px;
      }
      &.orderStatus {
        width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 575px) {
          width: 32%;
        }
      }
    }
    select {
      background-color: #d4f5fa;
      color: #17a2b8;
      outline: none;
      border: 1px solid #d4f5fa;
      width: 150px;
      padding: 0.375rem 0.75rem;
      border-radius: 5px;
      margin-left: 8px;
      @media (max-width: 575px) {
        width: 130px;
        margin-bottom: 10px;
      }
    }
    .status-dropdown {
      p {
        font-size: 11px;
        color: $lightgray1;
        margin: 0;
        text-align: $center;
        border-bottom: 1px solid #e7e7e7;
        padding: 0 5px 6px 5px;
      }
      li {
        a {
          font-size: 13px;
          display: flex;
          gap: 5px;
          small {
            color: $lightgray1;
          }
          .circle {
            margin-top: 5px;
          }
          .check-mark {
            margin-top: 4px;
          }
        }
      }
      &.assignDropdown {
        padding-top: 12px;
        padding-bottom: 12px;
        height: 260px;
        overflow: auto;
        li {
          .dropdown-item {
            padding: 4px 20px;
            &:hover {
              background-color: #e9e8e9;
            }
            .check-mark {
              position: $absolute;
              left: 4px;
            }
          }
        }
      }
    }
    .ticketUsersDropdown {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 150px;
      @media (max-width: 575px) {
        width: 120px;
      }
    }
  }
  .order-tags {
    padding: 20px;
    border: 1px solid #dee2e6;
    border-radius: 9px;
    margin-bottom: 15px;
    .form-group {
      label {
        color: $dark;
        font-weight: 700;
        font-size: 0.81rem;
        display: block;
        margin-bottom: 10px;
        text-transform: $uppercase;
      }
      .input-group {
        .form-control {
          box-shadow: none;
          font-size: $fs-14;
        }
        button {
          font-size: $fs-14;
        }
      }
      .css-b62m3t-container {
        padding: 0;
        border: 0;
        .css-1s2u09g-control {
          border-radius: 5px 0 0 5px;
        }
      }
    }
    .dataDropdown {
      top: 37px;
      width: 86%;
      @media (max-width: 1366px) {
        width: 85%;
      }
      @media (max-width: 1366px) {
        width: 83%;
      }
    }
    .tagsButton {
      background-color: $primary;
      border-color: $primary;
      color: $white;
    }
    .added-tags {
      a {
        background: #c5bff9;
        border: none;
        color: $purple2;
        text-decoration: none;
        padding: 3px 4px;
        font-size: 13px;
        font-weight: $semi-bold;
        border-radius: 3px;
        margin: 0 3px;
        .cencelTag {
          display: none;
          margin-left: 2px;
          i {
            font-size: 11px;
          }
        }
        &:hover {
          .cencelTag {
            display: inline-block;
          }
        }
      }
    }
  }
  .ticketInfo {
    padding: 20px;
    border: 1px solid #dee2e6;
    border-radius: 9px;
    margin-bottom: 15px;
    h4 {
      color: $dark;
      font-weight: 700;
      // font-size: $fs-16;
      // text-transform: $uppercase;
      margin-bottom: 20px;
      font-size: 24px;
    }
    p {
      font-size: 13px;
      margin-bottom: 10px;
      color: $lightgray1;
      @media (max-width: 575px) {
        word-break: break-all;
      }
      a {
        text-decoration: none;
        // color: $lightgray1;
      }
      &.infoText {
        color: $dark;
        @media (max-width: 575px) {
          word-break: break-all;
        }
        a {
          text-decoration: none;
          color: $dark;
        }
      }
    }
    ul {
      padding: 0;
      li {
        display: block;
        list-style-type: none;
        a {
          text-decoration: none;
          color: $purple1;
        }
      }
    }
  }
  .client-details {
    padding: 20px;
    border: 1px solid #dee2e6;
    border-radius: 9px;
  }
  .RecentTickets {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
    .accordion {
      .accordion-item {
        background-color: transparent;
        .accordion-header {
          .accordion-button {
            background-color: transparent;
            color: $dark;
            font-size: 0.81rem;
            font-weight: 700;
            text-transform: $uppercase;
            box-shadow: none;
          }
        }
      }
      .accordion-body {
        .ticketList {
          a {
            font-size: $fs-14;
            text-decoration: none;
            color: $dark;
          }
          p {
            color: $lightgray1;
            font-size: $fs-14;
          }
        }
      }
    }
  }
}
// view order ends

// history modal starts
.history-modal {
  .modal-header {
    .modal-title {
      color: $purple1;
      font-size: 18px;
      font-weight: $semi-bold;
    }
    .btn-close {
      background-size: 11px;
    }
  }
  .modal-body {
    .history-content {
      .form-group {
        label {
          color: $purple1;
          font-weight: $semi-bold;
          font-size: $fs-14;
          &.checkbox-label {
            font-weight: 500;
            small {
              color: $lightgray1;
            }
          }
        }
        p {
          font-size: $fs-14;
          color: $blue;
          margin-bottom: 0;
          a {
            text-decoration: none;
          }
        }
        select {
          background: url(../img/arrow-down-blue.svg);
          background-size: 9px;
          background-position: 96% 50%;
          background-repeat: no-repeat;
          color: $blue;
          font-size: $fs-14;
          box-shadow: none;
        }
        small {
          font-size: 11px;
        }
      }
      &.shareModal-content {
        .form-group {
          .input-group {
            gap: 10px;
            .form-control {
              border-radius: 5px;
            }
          }
          button {
            background-color: #f3f3f7;
            border: 1px solid #f3f3f7;
            color: #42526e;
            font-size: $fs-14;
            border-radius: 5px !important;
          }
          small {
            color: $lightgray1;
            a {
              text-decoration: none;
            }
          }
        }
      }
    }
    .delete-content {
      p {
        color: $purple1;
        font-size: $fs-14;
      }
    }
  }
  .modal-footer {
    button {
      font-size: $fs-14;
      border-radius: 10px;
      &.btn-secondary {
        background: #f3f3f7;
        border: 1px solid #f3f3f7;
        color: #42526e;
      }
      &.btn-primary {
        background-color: $primary;
        border-color: $primary;
        &:hover {
          background-color: $primary;
          border-color: $primary;
        }
      }
    }
  }
}
// history modal ends

// settings start
.section-setting {
  .setting-content {
    background-color: $white;
    padding: 20px;
    margin-top: 20px;
    .setting-inner {
      @media (max-width: 575px) {
        margin-top: 10px;
      }
      h4 {
        color: $primary;
        font-size: 18px;
        font-weight: $semi-bold;
      }
      .setting {
        min-height: 70px;
        margin-top: 20px;
        cursor: pointer;
        @media (max-width: 767px) {
          min-height: auto;
        }
        a {
          text-decoration: none;
          color: $dark;
          font-size: $fs-14;
          font-weight: $regular;
        }
        p {
          font-size: 13px;
          color: $lightgray1;
          margin: 0;
        }
      }
    }
  }
}
.setting-tab {
  background-color: $white;
  border-bottom: 2px solid #dee2e6;
  .tabs-content {
    .nav {
      justify-content: $center;
      @media (max-width: 767px) {
        justify-content: flex-end;
      }
      @media (max-width: 767px) {
        justify-content: flex-start;
      }
      .nav-item {
        .nav-link {
          background-color: transparent;
          color: #6f6f6f;
          font-size: $fs-14;
          outline: none;
          &.active {
            border-bottom: 3px solid $primary;
            border-radius: 0;
            color: $primary;
          }
        }
      }
    }
  }
}
.setting-form-section {
  .setting-form {
    .tags-heading {
      display: flex;
      justify-content: space-between;
      a {
        background-color: $primary;
        color: $white;
        font-size: $fs-14;
        text-decoration: none;
        font-weight: 400;
        padding: 7px 10px;
        border-radius: 10px;
      }
      .dropdown {
        .dropdown-menu {
          li {
            a {
              background-color: $white;
              color: $purple1;
            }
          }
        }
      }
    }
    .commonForm {
      background-color: $white;
      padding: 40px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      @media (max-width: 767px) {
        padding: 20px;
      }
      .statuses {
        border-bottom: 1px dashed #f3f3f3;
        padding: 7px;
        p {
          font-size: $fs-12;
          color: $lightgray1;
          margin-bottom: 0;
          line-height: 25px;
          span {
            padding: 1px 13px;
            border-radius: 5px;
            font-size: 12px;
            &.grey {
              background-color: #d3d8e1;
            }
            &.submit {
              color: #28a745;
              background-color: #d8f6df;
            }
            &.info {
              color: #0dcaf0;
              background-color: #d4f8ff;
            }
            &.response {
              color: #cf8c12;
              background: #ffda97;
            }
            &.red {
              color: #f00;
              background: #ffb6b6;
            }
          }
        }
      }
    }
    .settingForm {
      .form-group {
        .form-check {
          p {
            color: $lightgray1;
            font-size: $fs-12;
          }
        }
      }
    }
    .btn-section {
      margin: 20px 0;
      button {
        background-color: $primary;
        color: $white;
        font-size: $fs-14;
        padding: 7px 30px;
        border-radius: 5px;
        &:hover {
          background-color: $lightpurple;
          border-color: $lightpurple;
        }
        @media (max-width: 1024px) {
          padding: 7px 9px;
        }
      }
      .btn-reset {
        background-color: $white;
        color: $purple2;
        border-color: $purple2;
        margin-right: 10px;
        &:hover {
          color: $white;
        }
      }
      .btn-save {
        background-color: $primary;
        border-color: $primary;
        &:hover {
          background-color: #04747c !important;
          border-color: #04747c !important;
        }
      }
    }
    .previews-btn {
      margin: 20px 0;
      button {
        background: $white;
        border: 1px solid $white;
        box-shadow: 0 1px 1px 0 #dfe1e6;
        color: #42526e;
        border-radius: 0;
        font-size: $fs-14;
        @media (max-width: 1280px) {
          padding: 5px 8px;
        }
        @media (max-width: 1024px) {
          width: 100px;
        }
      }
    }
    h3 {
      color: $dark;
      font-size: 21px;
      font-weight: $semi-bold;
      margin: 15px 0;
      &.emailHeading {
        display: flex;
        justify-content: space-between;
        @media (max-width: 575px) {
          display: block;
        }
        .heading-btns {
          @media (max-width: 575px) {
            margin-top: 10px;
          }
          a {
            font-size: 15px;
            font-weight: 500;
            text-decoration: none;
            background: $white;
            border: 1px solid $white;
            box-shadow: 0 1px 1px 0 #dfe1e6;
            color: #42526e;
            padding: 6px 9px;
            &.inactive {
              opacity: 0.65;
            }
          }
        }
      }
    }

    .table {
      border: 1px solid #ededed;
      margin-bottom: 5px;
      tr {
        td {
          border-bottom: 1px solid #ededed;
          padding: 15px 20px;
          font-size: $fs-14;
          a {
            text-decoration: none;
            color: #6f6f6f;
            font-weight: 600;
          }
          .dropdown {
            text-align: right;
            button {
              background-color: transparent;
              border: 0;
              color: #42526e;
              padding: 0;
              &::after {
                display: none;
              }
            }
            .dropdown-menu {
              box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15);
              border: none;
              li {
                a {
                  color: $blue;
                  font-size: $fs-14;
                }
              }
            }
          }
          .team-detail {
            display: flex;
            align-items: $center;
            gap: 10px;
            .img {
              img {
                width: 50px;
                height: 50px;
                border-radius: 50px;
                object-fit: cover;
                @media (max-width: 767px) {
                  width: 40px;
                  height: 40px;
                }
              }
            }
            .name {
              p {
                font-size: 13px;
                color: #6f6f6f;
                margin: 0;
                &.userName {
                  color: $dark;
                  font-weight: $semi-bold;
                }
              }
            }
          }

          .userRoles {
            p {
              margin: 0;
              font-size: 13px;
              span {
                color: #6f6f6f;
                background-color: #eff3fd;
                margin-left: 10px;
                display: inline-block;
                font-size: 12px;
                padding: 2px 8px;
                border-radius: 5px;
              }
            }
          }
        }
        &:last-child {
          td {
            border: none;
          }
        }
      }
    }
    .team-table {
      @media (max-width: 767px) {
        overflow: auto;
      }
      .table {
        border: 0;
        border-collapse: separate;
        border-spacing: 0 1em;
        tr {
          td {
            border: 1px solid #ededed;
            border-left: 0;
            border-right: 0;
            padding: 10px;
            &:first-child {
              border-left: 1px solid #ededed;
            }
            &:last-child {
              border-right: 1px solid #ededed;
            }
            .dropdown {
              .dropdown-toggle {
                color: #6f6f6f;
                border: 1px solid #dee2e6;
                padding: 6px 15px;
              }
            }
          }
        }
      }
    }
    .add-role {
      text-align: right;
      a {
        color: $primary;
        text-decoration: none;
        font-size: $fs-14;
      }
      .btnDelete {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 5px 10px;
        cursor: pointer;
        display: inline-block;
        margin-bottom: 10px;
        color: $purple1;
      }
      .form-control {
        height: 40px;
        font-size: 14px;
        box-shadow: none;
        border-radius: 10px;
      }
    }
    .common-table {
      .table {
        border: 0;
        tr {
          th {
            border-color: #ebecf0;
          }
          td {
            padding: 0.5rem 0.5rem;
            .iconDel {
              background: #d9eced;
              color: #42526e;
              display: inline-flex;
              width: 30px;
              height: 30px;
              align-items: $center;
              justify-content: $center;
              border-radius: 7px;
              img {
                width: 100%;
                max-width: 20px;
              }
            }
          }
        }
      }
    }
    &.invoiceForm {
      .lp-copy-sel__control {
        margin: 0;
      }
    }
    .addTeam-btn {
      background-color: $primary;
      border-color: $primary;
      color: $white;
      text-decoration: none;
      display: inline-block;
      padding: 6px 20px;
      border-radius: 10px;
      font-size: $fs-14;
      margin-right: 10px;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: $primary;
        border-color: $primary;
      }
    }
    &.ticketForm {
      .messageAttachment {
        a {
          color: $purple1;
          font-size: 13px;
          text-decoration: none;
        }
      }
    }
    #fb-editor {
      @media (max-width: 991px) {
        margin-top: 10px;
      }
      .form-wrap {
        .stage-wrap {
          min-height: 300px !important;
          @media (max-width: 575px) {
            width: 100%;
          }

          li {
            padding: 20px;
          }
        }
        &.form-builder {
          .stage-wrap.empty {
            &::after {
              color: #6f6f6f;
            }
          }
        }
      }
      .cb-wrap {
        @media (max-width: 575px) {
          width: 100%;
        }
      }
      .frmb-control {
        // margin-top: 10px;
        @media (max-width: 575px) {
          display: flex;
        }
        .formbuilder-icon-autocomplete,
        .formbuilder-icon-button,
        .formbuilder-icon-select,
        .formbuilder-icon-radio-group,
        .formbuilder-icon-checkbox-group,
        .formbuilder-icon-hidden {
          display: none;
        }
        li {
          &::before {
            font-size: 20px;
          }
        }
      }
      .form-actions {
        float: none;
        .btn-danger {
          background-color: #dee2e6;
          color: $dark;
        }
        .save-template {
          background-color: $primary;
        }
      }
    }
    &.formOuter {
      .commonForm {
        padding: 0;
        // border: 1px solid #eff0f4;
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        h3 {
          border-bottom: 1px solid $lightgray3;
          color: $dark;
        }
        .form-group {
          label {
            color: $dark;
          }
          .form-control {
            border: 1px solid $lightgray3;
            border-radius: 5px;
          }
        }
        .btn-section {
          .btn {
            padding: 12px;
            border-radius: 5px;
            width: 130px;
            font-weight: $semi-bold;
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
            &.btn-save {
              background-color: $primary;
              &:hover {
                border-color: $primary;
              }
            }
            &.btn-cancel {
              background: $lightgray3;
              border-radius: 5px;
              color: $dark;
              margin-right: 10px;
              &:hover {
                border-color: $lightgray3;
              }
            }
          }
        }
      }
    }
    .rolesListSection {
      background: #ffffff;
      border: 1px solid #eff0f4;
      border-radius: 12px;
      padding: 30px;
      h3 {
        border-bottom: 2px solid #dee2e6;
      }
      .RolesData {
        border: 1px solid #dee2e6;
        // border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        color: #6f6f6f;
        .dropdown {
          .dropdown-toggle {
            color: #6f6f6f;
            border: 1px solid #dee2e6;
            padding: 6px 15px;
          }
        }
      }
    }
  }

  .section_Creates {
    border: 1px solid #dee2e6;
    padding: 20px 20px;
    border-radius: 10px;
    h4 {
      font-weight: 700;
      font-size: 22px;
    }
    .form-group {
      label {
        font-weight: 500;
        margin-bottom: 7px;
      }
      .form-control {
        box-shadow: none;
        width: 100%;
      }
      select {
        background-image: url(../img/arrow-down-blue.svg);
        background-repeat: no-repeat;
        background-size: 14px;
        background-position: 97% 50%;
      }
    }
  }

  .tagSection {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    .btn-primary {
      background-color: transparent;
      color: $dark;
      border: none;
      width: 100%;
      text-align: left;
      padding: 15px;
      font-weight: 600;
      span {
        float: right;
        img {
          width: 100%;
          max-width: 15px;
        }
      }
      i {
        color: #6f6f6f;
      }
    }
    .card-body {
      border: 0;
      border-radius: 0;
      border-top: 1px solid #dee2e6;
      .form-check-input {
        box-shadow: none;
        &:checked {
          background-color: $primary;
        }
      }
    }
    .advanceOption {
      p {
        position: relative;
        border: 1px dashed #dee2e6;
        border-radius: 5px;
        margin: 0;
        height: 150px;
        color: #6f6f6f;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        span {
          display: block;
          margin-bottom: 10px;
        }
        input {
          position: absolute;
          cursor: pointer;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    }
  }
  .tagsTable {
    .table {
      tr {
        td,
        th {
          border: 1px solid #dee2e6;
          border-left: 0;
          border-right: 0;
          padding: 15px !important;
          &:first-child {
            border-left: 1px solid #dee2e6;
          }
          &:last-child {
            border-right: 1px solid #dee2e6;
          }
        }
      }
    }
  }

  .couponForm {
    .form-group {
      .input-group {
        .btn-generate {
          background-color: $primary;
          border: 1px solid #f3f3f7;
          color: $white;
          border-radius: 10px !important;
          font-size: $fs-14;
          margin-left: 10px !important;
          padding: 0 10px;
          height: 40px;
        }
      }
      a.discountLink {
        text-decoration: none;
        display: block;
        text-align: right;
        font-size: $fs-14;
        color: $primary;
        font-weight: $semi-bold;
        // margin-top: 5px;
      }
      .css-26l3qy-menu {
        z-index: 999;
      }
    }
    .addMore-discount {
      .row {
        @media (max-width: 767px) {
          margin-bottom: 5px;
        }
        .form-group {
          margin: 0;
          label {
            display: none;
            @media (max-width: 767px) {
              display: inline;
            }
          }
        }
        &:first-child {
          .form-group {
            label {
              display: inline-block;
            }
          }
        }
      }
    }
    .expiryDate {
      background-image: url(../img/calendar.png);
      background-size: $fs-16;
      background-position: 95% 50%;
      background-repeat: no-repeat;
    }
  }
  .statusTable {
    .table {
      tr {
        td {
          color: $purple1;
        }
      }
    }
  }
  .btn-payment {
    background-color: $primary;
    border-color: $primary;
    color: $white;
    border-radius: 10px;
    &:hover {
      background-color: $primary;
      border-color: $primary;
    }
    &:active {
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }
  .invoiceNote {
    color: $purple1;
    margin: 8px 0;
    font-size: $fs-14;
    font-style: italic;
  }
  .history-table .btnShowmore {
    display: block;
    text-align: $center;
    text-decoration: none;
    border-top: 1px solid $purple2;
    padding: 6px 0 0 0;
    color: $purple1;
    font-size: $fs-16;
  }
}
// settings end

// tags modal starts
.tags-modal {
  .modal-header {
    .btn-close {
      opacity: 1;
      background-size: 12px;
    }
  }
  .modal-footer {
    border: 0;
    button {
      font-size: $fs-14;
      min-width: 110px;
      max-width: 110px;
      border-radius: 5px;
      padding: 10px 5px;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
      font-weight: $semi-bold;
      font-size: $fs-14;
      &.btn-primary {
        background-color: $primary;
        border-color: $primary;
      }
      &.btn-secondary {
        background-color: #dee2e6;
        border-color: #dee2e6;
        color: $dark;
      }
    }
  }
}
// tags modal ends

// view client starts
.invoiceBytext {
  color: $lightgray1;
  font-size: 13px;
  margin-top: 5px;
  a {
    text-decoration: none;
    color: #007bff;
  }
}
.clients-table {
  @media (max-width: 991px) {
    overflow: auto;
    .table {
      tr {
        td {
          white-space: nowrap;
        }
      }
    }
  }
  @media (max-width: 575px) {
    overflow: auto;
  }
}
.client-heading {
  .userImg {
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
  .userName {
    margin-left: 10px;
    h3 {
      color: $dark;
      font-weight: $semi-bold;
    }
  }
  .btns {
    display: flex;
    justify-content: flex-end;
    a.btn-edit {
      background: $white;
      border: 1px solid #d2d2d2;
      box-shadow: 0 1px 1px 0 #dfe1e6;
      color: $dark;
      text-decoration: none;
      padding: 0.55rem 0.75rem;
      margin-left: 10px;
      border-radius: 5px;
      font-size: 14px;
      // &:hover {
      //   background: #fafafd;
      //   border: 1px solid #fafafd;
      //   color: #42526e;
      // }
    }
    .dropdown {
      .dropdown-toggle {
        background: $white;
        border: 0;
        box-shadow: 0 1px 1px 0 #dfe1e6;
        color: #42526e;
        margin-left: 10px;
        padding: 0.55rem 0.75rem;
        &::after {
          display: none;
        }
      }
      .dropdown-menu {
        border: none;
        box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15%);
        li {
          a {
            color: $blue;
            font-size: $fs-14;
          }
        }
      }
    }
  }
}
.client-details {
  background-color: $white;
  padding: 1.25rem 0px;
  margin-top: 20px;
  .invoiceHeading {
    color: $blue;
  }
  .subsInfo {
    font-size: $fs-16;
    font-weight: $semi-bold;
    color: $purple1;
    margin-bottom: 20px;
  }
  p {
    font-size: $fs-14;
    &.clientLabel {
      color: $lightgray1;
    }
    &.clientText {
      color: $dark;
      a {
        text-decoration: none;
        color: $purple2;
      }
    }
    &.teamRule-text {
      color: #007bff;
      cursor: pointer;
      text-transform: $capitalize;
    }
  }
  .btn-account {
    background-color: #f3f3f7;
    color: #42526e;
    font-size: $fs-14;
    &:hover {
      background-color: #f3f3f7;
    }
  }
  .table {
    margin: 0;
    tr {
      th {
        color: $lightgray1;
        font-size: $fs-14;
        font-weight: 500;
      }
      td {
        color: $purple1;
        font-size: $fs-14;
        a {
          text-decoration: none;
        }
      }
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
  .invoiceText {
    color: $blue;
    font-size: 1.125rem;
    font-weight: $semi-bold;
  }
  .mobileSpace {
    @media (max-width: 575px) {
      margin-top: 20px;
    }
  }
}
.history-table {
  background-color: $white;
  padding: 20px 20px;
  margin-bottom: 15px;
  .history-date {
    p {
      color: $lightgray1;
      font-size: $fs-14;
    }
  }
  .history-detail {
    p {
      color: $purple1;
      font-size: $fs-14;
      // cursor: pointer;
      span {
        font-weight: $semi-bold;
        background-color: #f3f3f7;
        padding: 3px 4px;
        border-radius: 5px;
        color: $lightgray1;
      }
    }
  }
}
.notes {
  padding: 20px;
  border-left: 3px solid #ffc107;
  border-radius: 5px;
  background-color: $white;
  position: $relative;
  .edid-note {
    position: $absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    a {
      text-decoration: none;
    }
  }
  p {
    width: 90%;
  }
}
.payments {
  .payment-table {
    background-color: $white;
    .table {
      tr {
        th {
          font-size: $fs-14;
          color: $lightgray1;
          text-transform: $uppercase;
          font-weight: 500;
        }
        td {
          color: $purple1;
          font-size: $fs-14;
          a {
            text-decoration: none;
            color: $purple2;
          }
        }
      }
    }
  }
}
// view client ends

// files starts
.orders-section {
  .topFilters {
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .FiltersChild {
      width: 170px;
      border: 1px solid #d2d2d2;
      border-radius: 5px;
      height: 38px;
      padding: 8px 10px;
      box-shadow: none;
      cursor: pointer;
      background-image: url(../img/greyArrow.svg);
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: 98% 50%;
      font-size: 14px;
      &.dateFilter {
        width: auto;
        input {
          padding: 0;
          border: 0;
          box-shadow: none;
        }
      }
    }
    .date-selection {
      width: auto;
      padding: 8px 15px 8px 8px;
    }
    .assignedUser {
      position: $relative;
      ul {
        position: absolute;
        background-color: #fff;
        border-radius: 0.25rem;
        box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15);
        display: none;
        left: 0;
        margin: 0;
        max-height: 270px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0;
        top: 37px;
        width: 100%;
        z-index: 11;
        &.active {
          display: block;
        }
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        }
        li {
          list-style-type: none;
          .dropdown-item {
            font-size: 14px;
            padding: 4px 4px;
            display: flex;
            align-items: center;
            .check-mark {
              margin-right: 6px;
            }
            small {
              font-size: 12px;
            }
          }
        }
      }
    }
    .btn {
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      padding: 9px 25px;
      font-size: 14px;
      &.btn-clear {
        background-color: #dee2e6;
        color: $dark;
      }
      &.btn-update {
        background-color: $primary;
        color: $white;
      }
    }
  }

  .common-table {
    &.files-table {
      .table {
        tr {
          td {
            color: #6f6f6f;
            a {
              color: #6f6f6f;
              text-decoration: none;
            }
            .servicenameImg {
              width: 100%;
              max-width: 18px;
              margin-right: 10px;
            }
          }
          &:last-child {
            td {
              border-bottom: 0;
            }
          }
          .dropdown-menu {
            li {
              a {
                color: $blue;
              }
            }
          }
        }
      }
    }
    &.TableOrders {
      @media (max-width: 767px) {
        overflow: auto;
        .table {
          tr {
            td,
            th {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
.topFilters {
  .mySelect__loading-indicator {
    display: none;
  }
  .mySelect__menu {
    word-wrap: break-word;
  }
}
.couponHeading {
  display: flex;
  justify-content: space-between;
}
// files ends

// services starts
.serviceTabs {
  .nav-pills,
  .nav-tabs {
    border: 0;
    .nav-item {
      .nav-link {
        background-color: transparent;
        color: #6f6f6f;
        border: none;
        border-bottom: 2px solid #fff;
        &.active {
          color: $primary;
          border-color: $primary;
          border-radius: 0;
        }
      }
    }
  }
}
.service-pricingForm {
  .nav-tabs {
    .nav-item {
      .nav-link {
        background-color: rgba(255, 255, 255, 0.5);
        color: $lightgray1;
        border-color: rgba(255, 255, 255, 0.5);
        border-radius: 0;
        font-size: $fs-14;
        border-radius: 0.25rem 0.25rem 0 0;
        margin-right: 5px;
        &.active {
          background-color: $white;
          color: $blue;
        }
      }
    }
  }
  a {
    text-decoration: none;
    font-size: $fs-14;
    display: block;
    margin-bottom: 10px;
  }
  .form-control {
    font-size: $fs-14;
  }
  .alert-warning {
    border-left: 6px solid #ffc107;
  }
}
.intakeForm {
  p {
    color: $blue;
    font-size: $fs-14;
    span {
      background-color: #f3f3f7;
      font-weight: $semi-bold;
      color: $lightgray1;
      padding: 3px 6px;
      border-radius: 4px;
    }
  }
  .form-group {
    .Upldimages {
      text-align: $center;
      position: $relative;
      border: 3px dashed #f3f3f7;
      padding: 20px 0;
      input {
        position: $absolute;
        cursor: pointer;
        width: 100%;
        height: 100%;
        opacity: 0;
        left: 0;
        top: 0;
      }
      i {
        color: #dbdbdb;
      }
      p {
        color: $lightgray1;
        margin-top: 8px;
        span {
          color: $purple1;
          background-color: transparent;
          padding: 0;
          font-weight: 500;
        }
      }
    }
  }
}
.previewImg {
  margin-top: 20px;
  .ImgSection {
    display: inline-block;
    position: $relative;
    margin-right: 20px;
    img {
      width: 70px;
      height: 70px;
    }
    .Del {
      position: $absolute;
      background-color: #e7e7e7;
      width: 25px;
      height: 25px;
      text-align: $center;
      border-radius: 50%;
      top: -10px;
      right: -10px;
      z-index: 1;
      cursor: pointer;
      i {
        color: $blue;
        font-size: 13px;
      }
    }
  }
}
.addItem {
  font-size: $fs-14;
  text-decoration: none;
  display: block;
  text-align: right;
  margin-bottom: 10px;
}
.recurring-section {
  margin-top: 10px;
  label {
    margin: 0 10px 0 0;
  }
  .form-control {
    border-radius: 5px 0 0 5px !important;
  }
  select {
    border: 0;
    background-color: #e9ecef;
    outline: none;
    height: 26px;
    // option:checked{
    // color: rgb(246, 250, 0);
    // background-color: rgb(5, 26, 1);
    // }
  }
  // option[selected] {
  //   background: red;
  // }
  // select[multiple]:focus option:checked {
  //   background: red linear-gradient(0deg, red 0%, red 100%);
  // }
}
// services ends

// dashboard starts
.dashboardSection {
  .dashboardFilters {
    @media (max-width: 768px) {
      margin-left: 16px;
      margin-top: 10px;
    }
    text-align: right;
    .dropdown {
      display: flex;
      justify-content: flex-end;
      @media (max-width: 767px) {
        display: block;
      }
      .dropdown-toggle {
        color: $dark;
        background-color: $white;
        box-shadow: 5px 5px 20px #dfe1e6;
        margin-left: 10px;
        @media (max-width: 767px) {
          margin: 0 0 10px 0;
        }
      }
      .btn-primary {
        background-color: $purple2;
        border-color: $purple2;
      }
      .form-control {
        width: 200px;
        margin-left: 10px;
        border-color: $white;
        box-shadow: none;
        font-size: $fs-14;
        border: 1px solid #d2d2d2;
        @media (max-width: 767px) {
          width: 100%;
          margin: 0 0 10px 0;
        }
      }
      .dashboardDropdown {
        background-image: url(../img/icons/arrowIcon.svg);
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: 95% 50%;
        width: 110px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        border: 1px solid #d2d2d2;
        color: $dark;
        @media (max-width: 767px) {
          width: 48%;
          margin-right: 3px;
        }
      }
    }
    .react-datepicker {
      position: absolute;
      right: 50px;
      top: 40px;
      z-index: 11;
      @media (max-width: 575px) {
        top: 90px;
      }
    }
    .react-datepicker__header {
      padding: 0;
    }
    .tooltipdatepickertext {
      display: none;
    }
  }
  .cartSection {
    background-color: $white;
    padding: 20px 0;
    .recharts-wrapper {
      width: 100% !important;
      @media (max-width: 767px) {
        height: 150px !important;
      }
      .recharts-surface {
        width: 100%;
        @media (max-width: 767px) {
          height: 150px !important;
        }
      }
      .recharts-legend-wrapper {
        display: none;
      }
      .recharts-layer {
        path {
          fill: rgb(136, 132, 216);
        }
      }
    }
  }
  .tile {
    background-color: #fafafa;
    padding: 20px 20px;
    border: 1px solid #eff0f4;
    border-radius: 12px;
    cursor: pointer;
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
    .tileImg {
      transition: all 0.3s ease-in-out;
      img {
        width: 100%;
        height: 60px;
        max-width: 60px;
        object-fit: contain;
      }
      @media (max-width: 991px) {
        margin-bottom: 10px;
      }
      svg {
        @media (max-width: 1440px) {
          width: 50px;
          height: 50px;
        }
        path {
          fill: $primary;
        }
      }
    }
    p {
      font-size: 17px;
      color: #676767;
      margin: 0;
      transition: all 0.3s ease-in-out;
      @media (max-width: 1440px) {
        font-size: 16px;
      }
    }
    h3 {
      color: $primary;
      font-size: 25px;
      font-weight: $semi-bold;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      .tileImg {
        transform: translate(0, -4px);
      }
    }
  }
  .card {
    .card-header {
      background-color: $white;
      padding: 0.8rem;
      h3 {
        font-size: 24px;
        color: $primary;
        font-weight: $semi-bold;
        @media (max-width: 575px) {
          font-size: 18px;
        }
      }
    }
    .card-body {
      .recharts-wrapper {
        width: 100% !important;
        @media (max-width: 767px) {
          width: 100% !important;
          height: 100% !important;
        }
        svg {
          width: 100% !important;
          @media (max-width: 767px) {
            width: 100% !important;
            height: 100% !important;
          }
        }
        .recharts-tooltip-wrapper {
          @media (max-width: 767px) {
            display: none;
          }
        }
        .recharts-default-legend {
          display: none;
        }
      }
    }
  }
}

.listingSection {
  .common-table {
    background-color: $white;
    .table {
      tr {
        th {
          color: $dark;
          font-size: $fs-15;
          text-transform: $capitalize;
          font-weight: $semi-bold;
        }
        td {
          color: #6f6f6f;
          font-size: $fs-15;
          a {
            text-decoration: none;
            color: $purple1;
          }
          small {
            color: $lightgray1;
          }
        }
        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }
  .sectionPagination {
    .page-item {
      .page-link {
        color: #6f6f6f;
        box-shadow: none;
        padding: 3px 6px;
        font-weight: 500;
        border-color: #eff0f4;
        i {
          font-size: 13px;
        }
      }
      &.active {
        .page-link {
          background-color: $white;
          color: $primary;
          border-color: #eff0f4;
        }
      }
    }
  }
}
// dashboard ends

// client panel starts
.sectionHome {
  padding: 50px 0;
  .homeTiles {
    // background-color: $white;
    margin-top: 30px;
    .tileText {
      padding: 10px;
      p {
        color: $lightgray1;
        font-size: $fs-14;
      }
      h3 {
        color: $primary;
        font-weight: $semi-bold;
      }
    }
  }
  .homeTiles {
    .tile {
      background-color: #fafafa;
      border-radius: 12px;
      // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border: 1px solid #eff0f4;
      padding: 20px;
      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
      .tileImg {
        transition: all 0.3s ease-in-out;
        img {
          width: 100%;
          max-width: 70px;
          height: 70px;
          object-fit: contain;
        }
        svg {
          path {
            fill: $primary;
          }
        }
      }
      &:hover {
        .tileImg {
          transform: translate(0, -4px);
        }
      }
    }
  }
}

// shop section starts
.shopSection {
  .heading {
    h2 {
      display: flex;
      justify-content: space-between;
    }
    .btn-primary {
      background-color: $white;
      color: $purple2;
      border-color: $purple2;
      border-radius: 10px;
      &:hover {
        background-color: $purple2;
        color: $white;
      }
    }
  }
  .card {
    border-radius: 5px;
    border: 0;
    min-height: 500px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
    @media (max-width: 767px) {
      min-height: auto;
    }
    &:hover {
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    }
    .serviceImg {
      width: 100%;
      height: 270px;
      object-fit: cover;
      @media (max-width: 1366px) {
        height: 250px;
      }
    }
    .card-body {
      h3 {
        color: $blue;
        font-size: 19px;
        font-weight: $semi-bold;
        text-transform: $capitalize;
      }
      .servicesContent {
        p {
          margin: 0;
        }
      }
      p {
        color: $blue;
        font-size: $fs-14;
      }
      ul {
        li {
          color: $blue;
          font-size: $fs-14;
        }
      }
    }
    .card-footer {
      background-color: $white;
      p {
        margin: 0;
        color: $purple1;
        font-size: 17px;
        font-weight: $semi-bold;
      }
      .btn-primary {
        font-size: $fs-14;
        background-color: $primary;
        border-color: $primary;
        border-radius: 10px;
        &:hover {
          background-color: $primary;
          border-color: $primary;
        }
        &:active {
          background-color: $primary !important;
          border-color: $primary !important;
        }
      }
    }
  }
  .pagination {
    margin-top: 15px;
    .page-item {
      .page-link {
        color: $purple1;
        &:hover {
          background-color: $purple1;
          color: $white;
          border-color: $purple1;
        }
        &:focus {
          box-shadow: none;
        }
      }
      &.active {
        .page-link {
          background-color: $purple1;
          border-color: $purple1;
          color: $white;
        }
      }
    }
  }
}
// shop section ends

// cart modal starts
.cart-modal {
  @media (max-width: 575px) {
    padding: 0 !important;
  }
  .cart-content {
    @media (max-width: 575px) {
      overflow: auto;
    }
    .table {
      tr {
        td {
          .cartQty {
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            align-items: $center;
            input {
              width: 60px;
              border: 1px solid #d1d1d1;
              height: 35px;
              outline: none;
              padding: 10px;
            }
            a {
              text-decoration: none;
              font-size: $fs-14;
            }
          }
          .input-group {
            .form-control {
              box-shadow: none;
              font-size: $fs-14;
            }
            .input-group-text {
              background-color: $purple1;
              border-color: $purple1;
              color: $white;
            }
          }
          @media (max-width: 575px) {
            white-space: nowrap;
          }
        }
        &:last-child {
          td {
            border: 0;
          }
        }
      }
    }
    .errorMessage {
      background-color: #f2d1d1;
      color: #f30d0d;
      padding: 5px 10px;
      font-size: $fs-14;
      border-radius: 3px;
    }
    .successMsg {
      color: #138c2f;
      background-color: #d4edda;
      padding: 5px 10px;
      font-size: $fs-14;
      border-radius: 3px;
      margin-top: 10px;
    }
  }
  .modalBtns {
    margin-top: 20px;
    a {
      text-decoration: none;
      font-size: $fs-14;
    }
    .btn-primary {
      font-size: $fs-14;
    }
  }
  &.viewCart-section {
    .cart-content {
      background-color: $white;
      padding: 20px;
    }
  }
  .btn-primary {
    background-color: $primary;
    border-color: $primary;
    &:hover {
      background-color: $primary;
      border-color: $primary;
    }
    &:active {
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }
}
// cart modal ends

// checkout page starts
.checkOut-page {
  .h-100vh {
    @media (max-width: 991px) {
      height: auto;
    }
  }
  .container-fluid {
    @media (max-width: 991px) {
      padding-right: 15px !important;
    }
  }
  .checkoutListing {
    @media (max-width: 1366px) {
      margin-top: 10px !important;
    }
    @media (max-width: 991px) {
      padding: 0;
    }
    p {
      color: $purple1;
      font-size: $fs-14;
      @media (max-width: 1366px) {
        font-size: 12px;
        margin-bottom: 7px;
      }
      a {
        text-decoration: none;
      }
      &.agencyName {
        color: $lightgray1;
        font-weight: $semi-bold;
        font-size: $fs-16;
        margin-bottom: 30px;
        @media (max-width: 1366px) {
          margin-bottom: 10px;
          font-size: $fs-14;
        }
        @media (max-width: 575px) {
          margin: 20px 0;
        }
      }
    }
    h5 {
      color: $purple1;
      font-size: 15px;
      margin-bottom: 15px;
    }
    h3 {
      color: $purple1;
      font-weight: $semi-bold;
      font-size: 26px;
      margin-bottom: 30px;
    }
    .billingRow {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 10px 0;
      .billingAddress {
        p {
          margin: 0;
        }
      }
    }
    .sectionHelp {
      margin-top: 10px;
      display: flex;
      align-items: $center;
      justify-content: space-between;
      p {
        color: $lightgray1;
        margin: 0;
      }
      .continueBtn {
        background-color: $primary;
        color: $white;
        padding: 7px 10px;
        text-decoration: none;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: $primary;
        }
      }
    }
    form {
      h4 {
        color: $purple1;
        font-size: $fs-16;
        @media (max-width: 1366px) {
          font-size: $fs-14;
        }
      }
      .form-group {
        .form-control {
          padding: 0.375rem 0.75rem;
          border: 1px solid $lightgray;
          border-radius: 10px;
          box-shadow: none;
          background-color: $white;
          font-size: $fs-14;
          height: 40px;
          @media (max-width: 1366px) {
            height: 33px;
          }
        }
        select {
          background: url(../img/arrow-down-blue.svg);
          background-size: 11px;
          background-position: 98% 50%;
          background-repeat: no-repeat;
          cursor: pointer;
        }
        p {
          color: $lightgray1;
        }
        .form-check-label {
          color: $purple1;
          @media (max-width: 1366px) {
            font-size: $fs-14;
          }
        }
      }
      .accordion-button:not(.collapsed) {
        background-color: $white;
      }
      .accordion-button {
        @media (max-width: 1366px) {
          padding: 6px 20px;
        }
        img {
          @media (max-width: 1366px) {
            width: 100%;
            max-width: 110px;
          }
        }
      }
      .accordion-button::after {
        display: none;
      }
      .accordion {
        .accordion-item {
          border-radius: 0 0 0.375rem 0.375rem;
        }
      }
      .paymentOption {
        background-color: $white;
        padding: 10px 20px;
        border: 1px solid $lightgray3;
        border-bottom: 0;
        @media (max-width: 1366px) {
          padding: 6px 20px;
        }
        .form-check-label {
          img {
            width: 100%;
            max-width: 100px;
          }
        }
        &:last-child {
          border-radius: 0 0 0.375rem 0.375rem;
        }
        .existingCard {
          img {
            width: 100%;
            max-width: 30px;
          }
        }
        .expDel {
          a {
            color: #201b50;
            i {
              font-size: 14px;
              margin-left: 10px;
            }
          }
        }
        .cardExpire {
          font-size: $fs-14;
          background-color: #f3f3f7;
          color: #201b50;
          padding: 2px 10px;
          border-radius: 0.375rem;
          @media (max-width: 1366px) {
            font-size: 12px;
          }
        }
      }
      .bnt-save {
        width: 100%;
        background-color: $primary;
        color: $white;
        margin-top: 10px;
      }
    }
    &.formCheckout {
      height: 94vh;
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      @media (max-width: 991px) {
        height: auto;
      }
    }
  }
  .summaryHead {
    // background-image: linear-gradient(to bottom, #5a67bd 0%, #004a99 100%);
    background-color: $purple1;
    display: grid;
    align-items: $center;
    padding: 90px;
    @media (max-width: 1366px) {
      padding: 40px;
    }
    @media (max-width: 991px) {
      height: auto;
      margin-top: 10px;
    }
    .summaryPage {
      h4 {
        color: $white;
      }
      .d-flex {
        justify-content: space-between;
        p {
          color: $white;
          font-size: $fs-14;
          margin: 0;
          &.totalPrice {
            font-size: 18px;
          }
        }
      }
      hr {
        border-color: $white;
      }
    }
  }
}
// checkout page ends
// client panel ends
.lp-copy-sel__option,
.lp-copy-sel__single-value {
  white-space: pre-wrap !important;
}

.card {
  border-color: $lightgray3;
  .card-header {
    border-color: $lightgray3;
  }
  .card-body {
    border-color: $lightgray3;
  }
}

.table {
  // border: 1px solid $lightgray3;
  margin-bottom: 0;
  th {
    &:first-child {
      border-top-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
    }
  }
  tr {
    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 10px;
        }
        &:last-child {
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
  thead,
  tbody {
    tr {
      th,
      td {
        border-color: $lightgray3;
        // padding: 15px 20px;
      }
    }
  }
}

.orders-section {
  .filter-drowdown {
    min-height: 200px;
    width: 250px;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 1em;
    }
    .assignedUser {
      margin: 10px 0;
      position: relative;
      background-image: url(../../assets/img/arrow-down.svg);
      background-position: 96% 50%;
      background-repeat: no-repeat;
      background-size: 9px;
      border: 1px solid #ced4da;
      border-radius: 5px;
      cursor: pointer;
      padding: 9px 6px;
      // position: relative;
      height: 39px;
      font-weight: 400;
      p {
        margin: 0;
        // font-size: 14px;
        margin: 0;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      ul {
        background-color: #fff;
        border-radius: 0.25rem;
        box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.15);
        display: none;
        left: 0;
        margin: 0;
        max-height: 270px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0;
        position: absolute;
        top: 30px;
        width: 100%;
        z-index: 11;
        &.active {
          display: block;
        }
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        }
        li {
          list-style-type: none;
          .dropdown-item {
            display: flex;
            gap: 8px;
            align-items: center;
          }
        }
      }
    }
  }
}
.text-end {
  .formfooterbtn {
    background-color: #007d87;
    color: white;
    &:hover {
      background-color: #1ba5af;
      color: white;
    }
  }
}

.modal-footer {
  .formfooterbtn {
    background-color: #007d87;
    color: white;
    &:hover {
      background-color: #1ba5af;
      color: white;
    }
  }
}

.dashboardresponsive {
  align-items: center;
  display: flex;
  justify-content: end;
  @media (max-width: 991px) {
    justify-content: start;
    margin-top: 12px;
    flex-wrap: wrap;
    align-items: center;
  }
}

@media only screen and (max-width: 428px) {
  .sideicon {
    padding: 0px !important;
  }
}

.notAssigned {
  background-color: #007d87;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;

  
}

.btn-section.servicebtncontainer{
  padding: 0px 36px;
}

.mySelect__menu-list .mySelect__option--is-selected{
  background-color: rgba(0,125,135,0.8) !important;
}

.mySelect__menu-list .mySelect__option--is-focused{
  background-color: rgba(0,125,135,0.1);;
}


.mySelect__menu-list .mySelect__option.mySelect__option--is-selected small{
  color: white !important;
}

.mySelect__control.mySelect__control--is-focused{
  // border:2px solid #007d87;
  border-color: #007d87;
  box-shadow: none
}

.mySelect__control.mySelect__control--is-focused:hover{
  border-color: #007d87;
  
}


.form-builder-dialog.positioned{
  top: 50% !important;
  left:50% !important;
}

    @media screen and (max-width:767px) {
      .client-heading .btns.invoiceshareoptions{
      justify-content: start;
    }
}

.invoiceText{
  @media screen and (max-width:767px) {
    margin-top: 15px;
  }
}

.dropdown.ticketdropdown{
  @media screen and (max-width:575px) {
    text-align: left ;
  }

}

.dropdown.ticketdropdown button{
  margin-top: 5px;
}


.viewticketcontainer{
  padding: 20px !important;
  @media screen and (max-width:525px) {
    padding: 10px !important;
  }
}

.testingcontainer{
  padding: 20px !important;
  @media screen and (max-width:575px){
    padding: 10px !important;
  }
}
.clientviewoptions.btns{
  @media screen and (max-width:575px) {
      justify-content: left;
      margin-top: 10px;
  }
}


.crm-form-builder .react-form-builder.clearfix .col-md-3.react-form-builder-toolbar.float-right{
  @media screen and (max-width:1150px) {
    width: 100% ;
    margin-top: 10px;
  }
  
}
.crm-form-builder .react-form-builder.clearfix>div{
  @media screen and (max-width:1150px) {
    flex-direction: column;
  }
}


.crm-form-builder .react-form-builder.clearfix .col-md-9.react-form-builder-preview.float-left{
  @media screen and (max-width:1150px) {
    width: 100% ;
   
  }
}

.searchSection{
  @media screen and (max-width:991px) {
    justify-content: start;
    margin-top: 10px;
  }
}

.form-check input[type="checkbox"]:checked{
 background-color: #007d87;
}